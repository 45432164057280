import React from "react";
import { addCommas, getProgress } from "../../helpers";
import avatar from "../../assets/icons/avatar.png";
import { managers } from "../../constants/actionTypes";

const AssignedManager = ({ topManagers, goto }) => {
  return (
    <>
      <div className="top-investors">
        {topManagers ? (
          topManagers.map((manager) => {
            let progress = topManagers
              ? getProgress(manager.balance, manager.target)
              : 0;
            let remaining = 100 - progress;
            return (
              <div className="manager" key={manager.name}>
                <div className="left-content">
                  <div className="media">
                    <a className="media-left media-middle" href="#">
                      {
                        <img
                          src={
                            manager.profile_image
                              ? manager.profile_image
                              : avatar
                          }
                          alt="profile image"
                        />
                      }
                    </a>
                    <div className="media-body">
                      <div
                        className="media-heading"
                        style={{ marginTop: "7px", marginLeft: "5px" }}
                      >
                        <span className="name">{manager.name}</span>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div
                  className="progress"
                  style={{ backgroundColor: "#292932", marginTop: "10px" }}
                >
                  <div
                    className="progress-bar progress-bar-striped active"
                    role="progressbar"
                    aria-valuemin="0"
                    aria-valuemax="100"
                    style={{ width: `${progress}%` }}
                  >
                    {progress}% completed
                  </div>
                  <div
                    className="progress-bar progress-bar-striped active"
                    role="progressbar"
                    aria-valuemin="0"
                    aria-valuemax="100"
                    style={{
                      width: `${remaining}%`,
                      backgroundColor: "#292932",
                    }}
                  >
                    {remaining}% remaining
                  </div>
                </div> */}
                <div className="right-content">
                  {manager && manager.investors
                    ? manager.investors + " investors"
                    : 0 + " investors"}
                  {/* {manager && manager.balance
                    ? "£" +
                      addCommas(Number(manager.balance).toFixed(2).toString())
                    : "£0"} */}
                </div>
              </div>
            );
          })
        ) : (
          <div className="no-data">
            <i>No data</i>
          </div>
        )}
        {topManagers ? (
          <>
            <hr style={{ marginTop: "0" }} />
            <div className="view-managers" onClick={() => goto("/managers")}>
              VIEW ALL MANAGERS
            </div>
          </>
        ) : null}
      </div>
    </>
  );
};

export default AssignedManager;
