import React from "react";

const AddTransactionModal = ({
  onChange,
  investors,
  onClickSave,
  stateData,
}) => {
  return (
    <div id="add-transaction-modal" className="modal fade" role="dialog">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <button type="button" className="close" data-dismiss="modal">
              &times;
            </button>
            <h4 className="modal-title">Add Transaction</h4>
          </div>
          <div className="modal-body">
            <div className="form-group">
              <label htmlFor="account_number">Investor</label>
              <select
                id="account_number"
                className="form-control"
                value={
                  stateData && stateData.account_number
                    ? stateData.account_number
                    : "Select Investor"
                }
                onChange={(e) => onChange(e)}
              >
                <option disabled> Select Investor</option>
                {investors
                  ? investors.map((investor) => (
                      <option key={investor.id} value={investor.account_number}>
                        {investor.first_name} {" " + investor.last_name}{" "}
                        {" (" + investor.account_number + ")"}
                      </option>
                    ))
                  : null}
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="amount">Amount</label>
              <input
                autoComplete="off"
                onChange={(e) => onChange(e)}
                type="text"
                placeholder="Enter Amount"
                className="form-control"
                id="amount"
                value={
                  stateData && stateData.amount !== null ? stateData.amount : ""
                }
              />
            </div>
            {/* <div className="form-group">
              <label htmlFor="currency">Currency</label>
              <select
                className="form-control"
                defaultValue="Select Currency"
                onChange={(e) => onChange(e)}
                id="currency"
                value={
                  stateData && stateData.currency
                    ? stateData.currency
                    : "Select Currency"
                }
              >
                <option disabled>Select Currency</option>
                <option value="GBP">GBP (United Kingdom)</option>
                <option value="EUR"> EUR (European Union)</option>
                <option value="USD">USD (United States)</option>
                <option value="AED">AED (Dubai)</option>
                <option value="RUB">RUB (Russia)</option>
                <option value="LIRA">LIRA (Turkey)</option>
                <option value="KZT">KZT (Kazakhstan)</option>
              </select>
            </div> */}
          </div>
          <div className="modal-footer">
            <button className="btn btn-primary" onClick={() => onClickSave()}>
              Save
            </button>
            <button
              type="button"
              className="btn"
              data-dismiss="modal"
              style={{ backgroundColor: "#13131a", marginRight: "10px" }}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddTransactionModal;
