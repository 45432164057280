import { documents } from "../../constants/actionTypes";

const initialState = {
  all_documents: null,
  selected_document: null,
};

function documentsReducer(state = initialState, action) {
  switch (action.type) {
    case documents.ALL_DOCUMENTS:
      return { ...state, all_documents: action.payload };
    case documents.SELECTED_DOCUMENT:
      return {
        ...state,
        selected_document: action.payload,
      };
    default:
      return state;
  }
}
export default documentsReducer;
