import React from "react";
import EnhancedDocumentsTable from "./listOfDocuments";

export default function Documents({
  stateData,
  documents,
  onClickVerify,
  onClickReject,
}) {
  return (
    <EnhancedDocumentsTable
      rows={documents}
      selected={stateData ? stateData.selected : 0}
      onClickReject={(id) => onClickReject(id)}
      onClickVerify={(id) => onClickVerify(id)}
    />
  );
}
