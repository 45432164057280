import React from "react";
import { connect } from "react-redux";
import { transactions as trans } from "../../constants/actionTypes";
import {
  getTransactions,
  loaderState,
  sortData,
  getUsers,
  addTransaction,
  deteleTransaction,
} from "../../actions";
import {
  isEmptyOrNull,
  filterData,
  createTransactionsData,
} from "../../helpers";
import $ from "jquery";
import _ from "lodash";
import TransactionsComp from "../../components/Transactions";
import swal from "sweetalert";
import moment from "moment";

class Transactions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: [],
      sortBy: "Newest",
    };
  }

  componentDidMount() {
    this.props.loaderState(true);
    this.props.getTransactions();
    this.props.getUsers();
  }

  setSelected = (selected) => {
    this.setState({ selected });
  };

  sort = (order, sortBy) => {
    this.setState({ sortBy });
    const { transactions } = this.props;
    this.props.sortData(
      transactions,
      trans.ALL_TRANSACTIONS,
      "date_time",
      order
    );
  };

  filter = (array, text) => {
    let filteredData = filterData(array, text);
    return filteredData;
  };

  onFilterTextChange = ({ target }) => {
    this.setState({ filterText: target.value });
  };

  onChange = ({ target }) => {
    const { id, value } = target;
    this.setState({ [id]: value });
  };

  onClickSave = () => {
    const { amount, account_number, currency } = this.state;
    if (
      isEmptyOrNull(amount) ||
      isEmptyOrNull(account_number)
      // isEmptyOrNull(currency)
    ) {
      swal("", "Please select all the fields", "info");
    } else {
      this.setState({ amount: "", account_number: "", currency: "" });
      this.props.loaderState(true);
      const { all_users } = this.props;
      let investor = null;
      _.forEach(all_users, (user) => {
        if (user.account_number === account_number) {
          investor = { ...user };
        }
      });
      let data = {
        amount: Number(amount),
        account_number,
        currency: investor.currency,
        new_balance: Number(investor.balance) + Number(amount),
        balance: Number(investor.balance),
        status: "Completed",
        transaction_type: "Deposit",
        date_time: moment().format(),
      };
      delete investor.addressDettails;
      delete investor.bankingDetails;
      delete investor.managerDetails;
      this.setState({ amount: null, account_number: null, currency: null });
      this.props.addTransaction(data, investor);
      $(".close").click();
    }
  };

  onDelete = (transaction) => {
    swal("", "Please contact customer services", "info");
    // this.setState({ action: "Delete" });
    // let selected = this.state.selected;
    // if (selected.length === 0) {
    //   this.setState({ action: "Actions" });
    //   swal("", "Please select a record!", "info");
    // } else {
    // swal({
    //   title: "Are you sure?",
    //   text: "You want to delete this record!",
    //   icon: "warning",
    //   buttons: ["Cancel", "Delete"],
    //   dangerMode: true,
    // }).then((willDelete) => {
    //   if (willDelete) {
    //     this.props.loaderState(true);
    //     this.props.deteleTransaction(transaction);
    //   }
    // });
  };

  render() {
    const { transactions, all_users } = this.props;
    const { filterText } = this.state;

    let trans = transactions ? createTransactionsData(transactions) : null;

    let filteredTransactions = !isEmptyOrNull(filterText)
      ? this.filter(trans, filterText)
      : trans;
    let stateData = { ...this.state };

    return (
      <TransactionsComp
        transactions={filteredTransactions}
        setSelected={(newSelected) => this.setSelected(newSelected)}
        sort={(order, sortBy) => this.sort(order, sortBy)}
        onFilterTextChange={(e) => this.onFilterTextChange(e)}
        investors={all_users}
        onChange={(e) => this.onChange(e)}
        onClickSave={() => this.onClickSave()}
        onDelete={(e) => this.onDelete(e)}
        stateData={stateData}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    transactions: state.transactions.transactions,
    all_users: state.users.all_users,
  };
};

export default connect(mapStateToProps, {
  loaderState,
  getTransactions,
  sortData,
  getUsers,
  addTransaction,
  deteleTransaction,
})(Transactions);
