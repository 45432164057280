import React from "react";
import { connect } from "react-redux";
import {
  selectedNav,
  getTransactions,
  getManagers,
  loaderState,
  getTotalInvestments,
  getInvestorsCount,
  getUsers,
  getActiveUsers,
  getRounds,
  getCompanyInfo,
} from "../../actions";
import {
  goto,
  investorsChartData,
  managersChartData,
  getDashboardBalanceGraphData,
  getDashboardCurrenciesData,
} from "../../helpers";
import _ from "lodash";
import DashboardComp from "../../components/Dashboard";

class Dashboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    this.props.loaderState(true);
    this.props.getTransactions();
    this.props.getInvestorsCount();
    this.props.getUsers();
    this.props.getActiveUsers();
    this.props.getTotalInvestments();
    this.props.getManagers();
    this.props.getTransactions(1, 5, true);
    this.props.getRounds();
    this.props.getCompanyInfo();
  }

  onGoto = (path) => {
    this.props.loaderState(true);
    this.props.selectedNav(path);
    setTimeout(() => {
      goto(path);
    }, 100);
  };

  getTopInvestors = (investors) => {
    let all_investors = _.orderBy(investors, ({ balance }) => balance || "", [
      "desc",
    ]);
    let top = [];
    let count = 0;
    _.forEach(all_investors, (TI) => {
      if (count < 5) {
        if (TI.balance) {
          top.push(TI);
          count++;
        }
      }
    });

    return top;
  };

  getManagersDataSet = () => {
    const { managers } = this.props;

    let topManagers = [];
    _.forEach(managers, (manager) => {
      let data = {
        name: manager.first_name + " " + manager.last_name,
        investors: manager.investors,
      };
      if (manager.investors) {
        // data.balance = _.sumBy(manager.investors, "balance");
        data.investorsCount = manager.investors.length;
      }
      topManagers.push(data);
    });
    topManagers = _.orderBy(topManagers, ["investorsCount"], ["desc"]);
    topManagers = topManagers.slice(0, 5);
    let finalData = managersChartData(topManagers);

    return finalData;
  };

  compare(a, b) {
    let comparison = 0;
    if (a.balance < b.balance) {
      comparison = 1;
    } else {
      comparison = -1;
    }
    return comparison;
  }

  getInvestorDataSet = () => {
    const { activeUsers } = this.props;
    // let investors = _.orderBy(activeUsers, ["balance"], ["desc"]);
    let investors = activeUsers;
    investors.sort(this.compare);
    investors = investors.slice(0, 25);
    let datasets = investorsChartData(investors);

    return datasets;
  };

  getTopManagers = (managers) => {
    let topManagers = [];
    _.forEach(managers, (manager) => {
      let data = {
        name: manager.first_name + " " + manager.last_name,
      };
      if (manager.investors) {
        // data.balance = _.sumBy(manager.investors, "balance");
        data.investors = manager.investors.length;
      }
      data.target = manager.target;
      topManagers.push(data);
    });

    topManagers = _.orderBy(topManagers, ["investors"], ["desc"]);
    topManagers = topManagers.slice(0, 5);
    return topManagers;
  };

  getStartedRound = (rounds) => {
    let startedRounds = 0;
    _.forEach(rounds, (round) => {
      if (round.status === "Started") startedRounds += 1;
    });

    return startedRounds;
  };

  render() {
    const {
      allInvestors,
      transactions,
      activeUsers,
      totalInvestment,
      totalInvestors,
      managers,
      rounds,
      allTransacions,
      companyDetails,
    } = this.props;

    const {
      graphLabels,
      GBPData,
      LIRAData,
      USDData,
      KZTData,
      AEDData,
      RUBData,
      EURData,
    } = allTransacions
      ? getDashboardCurrenciesData(
          _.orderBy(allTransacions, ["date_time"], "asc")
        )
      : {};

    const balance = {
      labels: graphLabels ? graphLabels : [],
      datasets: [
        {
          fill: false,
          label: "(£) Currency GBP",
          backgroundColor: "rgba(0,0,255,0.4)",
          borderColor: "rgba(0,0,255,1)",
          borderJoinStyle: "miter",
          pointBorderColor: "rgba(0,0,255,1)",
          pointHoverBackgroundColor: "rgba(0,0,255,1)",
          pointHoverBorderColor: "rgba(0,0,255,1)",
          pointRadius: 3,
          data: GBPData ? GBPData : [],
        },
        {
          fill: false,
          label: "(₺) Currency LIRA",
          backgroundColor: "rgba(255,0,0,0.4)",
          borderColor: "rgba(255,0,0,1)",
          pointHoverBackgroundColor: "rgba(255,0,0,1)",
          pointHoverBorderColor: "rgba(255,0,0,1)",
          pointHoverBackgroundColor: "rgba(255,0,0,1)",
          pointHoverBorderColor: "rgba(255,0,0,1)",
          pointRadius: 3,
          data: LIRAData ? LIRAData : [],
          type: "line",
        },
        {
          fill: false,
          label: "($) Currency USD",
          backgroundColor: "rgba(255,255,0,0.4)",
          borderColor: "rgba(255,255,0,1)",
          pointHoverBackgroundColor: "rgba(255,255,0,1)",
          pointHoverBorderColor: "rgba(255,255,0,1)",
          pointHoverBackgroundColor: "rgba(255,255,0,1)",
          pointHoverBorderColor: "rgba(255,255,0,1)",
          pointRadius: 3,
          data: USDData ? USDData : [],
          type: "line",
        },
        {
          fill: false,
          label: "(₸) Currency KZT",
          backgroundColor: "rgba(255,0,255,0.4)",
          borderColor: "rgba(255,0,255,1)",
          pointHoverBackgroundColor: "rgba(255,0, 255,1)",
          pointHoverBorderColor: "rgba(255,0,255,1)",
          pointHoverBackgroundColor: "rgba(255,0,255,1)",
          pointHoverBorderColor: "rgba(255,0,255,1)",
          pointRadius: 3,
          data: KZTData ? KZTData : [],
          type: "line",
        },
        {
          fill: false,
          label: "(د.إ)" + " Currency AED",
          backgroundColor: "rgba(0,255,255,0.4)",
          borderColor: "rgba(0,255,255,1)",
          pointHoverBackgroundColor: "rgba(0,255, 255,1)",
          pointHoverBorderColor: "rgba(0,255,255,1)",
          pointHoverBackgroundColor: "rgba(0,255,255,1)",
          pointHoverBorderColor: "rgba(0,255,255,1)",
          pointRadius: 3,
          data: AEDData ? AEDData : [],
          type: "line",
        },
        {
          fill: false,
          label: "(₽) Currency RUB",
          backgroundColor: "rgba(155,255,255,0.4)",
          borderColor: "rgba(155,255,255,1)",
          pointHoverBackgroundColor: "rgba(155,255, 255,1)",
          pointHoverBorderColor: "rgba(155,255,255,1)",
          pointHoverBackgroundColor: "rgba(155,255,255,1)",
          pointHoverBorderColor: "rgba(155,255,255,1)",
          pointRadius: 3,
          data: RUBData ? RUBData : [],
          type: "line",
        },
        {
          fill: false,
          label: "(€) Currency EUR",
          backgroundColor: "rgba(155,0,255,0.4)",
          borderColor: "rgba(155,0,255,1)",
          pointHoverBackgroundColor: "rgba(155,0, 255,1)",
          pointHoverBorderColor: "rgba(155,0,255,1)",
          pointHoverBackgroundColor: "rgba(155,0,255,1)",
          pointHoverBorderColor: "rgba(155,0,255,1)",
          pointRadius: 3,
          data: EURData ? EURData : [],
          type: "line",
        },
      ],
    };

    let investorsDataset = activeUsers ? this.getInvestorDataSet() : null;
    let managersDataset = managers ? this.getManagersDataSet() : null;

    let topManagers = managers ? this.getTopManagers(managers) : null;
    let topInvestors = allInvestors ? this.getTopInvestors(allInvestors) : null;

    let startedRounds = rounds ? this.getStartedRound(rounds) : null;

    return (
      <DashboardComp
        startedRounds={startedRounds}
        goto={(path) => this.onGoto(path)}
        balance={balance}
        investors={investorsDataset}
        managers={managersDataset}
        topManagers={topManagers}
        topInvestors={topInvestors}
        totalInvestment={Number(totalInvestment).toFixed(2)}
        totalInvestors={totalInvestors}
        activeUsers={activeUsers}
        transactions={transactions}
        companyDetails={companyDetails}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    allInvestors: state.users.all_users,
    totalInvestment: state.users.total_investment,
    totalInvestors: state.users.total_investors,
    activeUsers: state.users.active_users,
    transactions: state.transactions.dashboard_transactions,
    managers: state.managers.all_managers,
    rounds: state.rounds.all_rounds,
    allTransacions: state.transactions.transactions,
    companyDetails: state.auth.company_details,
  };
};

export default connect(mapStateToProps, {
  selectedNav,
  getManagers,
  loaderState,
  getTransactions,
  getTotalInvestments,
  getInvestorsCount,
  getUsers,
  getActiveUsers,
  getRounds,
  getCompanyInfo,
})(Dashboard);
