import { rounds } from "../../constants/actionTypes";

const initialState = {
  all_rounds: null,
  selected_round: null,
};

function roundsReducer(state = initialState, action) {
  switch (action.type) {
    case rounds.ROUNDS:
      return { ...state, all_rounds: action.payload };
    case rounds.SELECTED_ROUND:
      return {
        ...state,
        selected_round: action.payload,
      };
    default:
      return state;
  }
}
export default roundsReducer;
