import React, { useState } from "react";
import PropTypes from "prop-types";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import { tableStyles } from "../Common/StyleComponents";
import CheckIcon from "@material-ui/icons/CheckCircleOutline";
import TimesIcon from "@material-ui/icons/HighlightOff";
import DollorIcon from "@material-ui/icons/MonetizationOnOutlined";
import { isEmptyOrNull, addCommas, getCurrencySymbol } from "../../helpers";

const headCells = [
  {
    id: "transaction_id",
    numeric: false,
    disablePadding: true,
    label: "ID",
  },
  {
    id: "dateRequested",
    numeric: false,
    disablePadding: false,
    label: "DATE",
  },
  {
    id: "investorName",
    numeric: false,
    disablePadding: false,
    label: "INVESTOR",
  },
  {
    id: "amount",
    numeric: false,
    disablePadding: false,
    label: "AMOUNT",
  },
  {
    id: "curreny",
    numeric: false,
    disablePadding: false,
    label: "CURRENCY",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "STATUS",
  },
  { id: "type", numeric: false, disablePadding: false, label: "TYPE" },
  { id: "actions", numeric: false, disablePadding: false, label: "ACTIONS" },
];

function EnhancedWithdrawalTableHead(props) {
  const { classes, onSelectAllClick, numSelected, rowCount } = props;

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            style={{ color: "#fff" }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedWithdrawalTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function EnhancedWithdrawalTable({
  rows,
  selected,
  setSelected,
  updateStatus,
  onSelectWithdrawal,
  onClickActions,
  onFilterTextChange,
  action,
}) {
  const classes = tableStyles();
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.transaction_id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  return (
    <Paper className={classes.paper}>
      <div className="row" style={{ padding: "15px 15px 0 15px" }}>
        <div className="col-sm-10 col-xs-9">
          <div className="form-group">
            <input
              style={{ backgroundColor: "#1c1c24" }}
              type="text"
              placeholder="Search Withdrawals"
              className="form-control"
              id="search-withdrawals"
              onChange={(e) => onFilterTextChange(e)}
            />
            {/* <div className="icon-addon addon-md">
              <input
                style={{ backgroundColor: "#1c1c24" }}
                type="text"
                placeholder="Search Withdrawal"
                className="form-control"
                id="search-withdrawals"
                onChange={(e) => onFilterTextChange(e)}
              />
              <label
                htmlFor="search-withdrawals"
                className="glyphicon glyphicon-search"
                rel="Search Withdrawal"
                title="Search Withdrawal"
              ></label>
            </div> */}
          </div>
        </div>
        <div className="col-sm-2 col-xs-3">
          <select
            className="form-control"
            style={{ backgroundColor: "#1c1c24" }}
            value={action ? action : "Actions"}
            onChange={(e) => onClickActions(e.target.value)}
          >
            <option disabled>Actions</option>
            <option>Complete</option>
            <option>Cancel</option>
          </select>
        </div>
      </div>
      <TableContainer>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size={dense ? "small" : "medium"}
          aria-label="enhanced table"
          id="withdrawals-table"
        >
          <EnhancedWithdrawalTableHead
            classes={classes}
            numSelected={selected.length}
            onSelectAllClick={handleSelectAllClick}
            rowCount={rows ? rows.length : 0}
          />
          <TableBody>
            {rows
              ? rows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.transaction_id);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.transaction_id}
                        selected={isItemSelected}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            onClick={
                              rows && rows.length > 0
                                ? (event) =>
                                    handleClick(event, row.transaction_id)
                                : null
                            }
                            checked={isItemSelected}
                            inputProps={{ "aria-labelledby": labelId }}
                            style={{ color: "#fff" }}
                          />
                        </TableCell>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                          style={{ color: "gray" }}
                        >
                          {row.transaction_id}
                        </TableCell>
                        <TableCell align="left" style={{ color: "gray" }}>
                          {row.date_time}
                        </TableCell>
                        <TableCell align="left" style={{ color: "gray" }}>
                          {row.name}
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            color: "gray",
                          }}
                        >
                          {row.amount}
                        </TableCell>
                        <TableCell align="left" style={{ color: "gray" }}>
                          {row.currency}
                        </TableCell>
                        <TableCell>
                          {!isEmptyOrNull(row.status) ? (
                            <span
                              className="status"
                              style={{
                                color:
                                  row.status === "Completed"
                                    ? "#27AE60"
                                    : row.status === "Canceled"
                                    ? "#C0392B"
                                    : "#FFFF00",
                                backgroundColor:
                                  row.status === "Completed"
                                    ? "rgb(34, 153, 84, 0.2)"
                                    : row.status === "Canceled"
                                    ? "rgb(169, 50, 38, 0.2)"
                                    : "rgb(255,255,0, 0.2)",
                              }}
                            >
                              {row.status}
                            </span>
                          ) : null}
                        </TableCell>
                        <TableCell style={{ color: "gray" }}>
                          {row.withdrawl_type}
                        </TableCell>
                        <TableCell style={{ color: "gray" }}>
                          <CheckIcon
                            onClick={() =>
                              updateStatus(row.transaction_id, "Completed")
                            }
                            style={{
                              marginRight: "5px",
                              color: "green",
                              pointerEvents:
                                row.status === "Completed" ||
                                row.status === "Canceled"
                                  ? "visible"
                                  : "",
                              cursor:
                                row.status === "Completed" ||
                                row.status === "Canceled"
                                  ? "not-allowed"
                                  : "pointer",
                            }}
                          />
                          <TimesIcon
                            onClick={() =>
                              updateStatus(row.transaction_id, "Canceled")
                            }
                            style={{
                              marginRight: "5px",
                              color: "red",
                              pointerEvents:
                                row.status === "Completed" ||
                                row.status === "Canceled"
                                  ? "visible"
                                  : "",
                              cursor:
                                row.status === "Completed" ||
                                row.status === "Canceled"
                                  ? "not-allowed"
                                  : "pointer",
                            }}
                          />
                          <DollorIcon
                            onClick={() => onSelectWithdrawal(row)}
                            data-toggle="modal"
                            data-target="#bankDetailsModal"
                            style={{ color: "yellow" }}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })
              : null}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 15]}
        component="div"
        count={rows ? rows.length : 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
