import React from "react";
import Select from "react-select";
import Tabs from "../Common/tabs";
import moment from "moment";

const NewRoundModal = ({
  onClickSave,
  investors,
  investor,
  handleChange,
  onChange,
  pastStartDate,
  pastCompleteDate,
}) => {
  let maxDate = moment().format("YYYY-MM-DD");
  return (
    <div id="add-round-modal" className="modal fade" role="dialog">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <button type="button" className="close" data-dismiss="modal">
              &times;
            </button>
            <h4 className="modal-title">Create Investment Round</h4>
          </div>
          <Tabs tabs={["New", "Past"]} className="tabs" />
          <div className="tab-content">
            <div id="New" className="tab-pane active">
              <div className="modal-body">
                <div className="form-group">
                  <label htmlFor="round_name">Select Investor</label>
                  <Select
                    id="round_name"
                    name="round_name"
                    isClearable
                    value={investor}
                    onChange={handleChange}
                    options={investors}
                  />
                </div>
              </div>
              <div className="modal-footer">
                <button
                  className="btn btn-primary"
                  onClick={() => onClickSave()}
                >
                  Save
                </button>
                <button
                  type="button"
                  className="btn"
                  data-dismiss="modal"
                  style={{ backgroundColor: "#13131a", marginRight: "10px" }}
                >
                  Close
                </button>
              </div>
            </div>
            <div id="Past" className="tab-pane">
              <div className="modal-body">
                <div className="form-group">
                  <label htmlFor="round_name">Select Investor</label>
                  <Select
                    id="round_name"
                    name="round_name"
                    isClearable
                    value={investor}
                    onChange={handleChange}
                    options={investors}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="complete_date">Start Date</label>
                  <input
                    onChange={(e) => onChange(e)}
                    className="form-control"
                    type="date"
                    id="pastStartDate"
                    value={moment(pastStartDate).format("YYYY-MM-DD")}
                    max={maxDate}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="complete_date">Completion Date</label>
                  <input
                    onChange={(e) => onChange(e)}
                    className="form-control"
                    type="date"
                    id="pastCompleteDate"
                    value={moment(pastCompleteDate).format("YYYY-MM-DD")}
                    max={maxDate}
                  />
                </div>
              </div>
              <div className="modal-footer">
                <button
                  className="btn btn-primary"
                  onClick={() => onClickSave(true)}
                >
                  Save
                </button>
                <button
                  type="button"
                  className="btn"
                  data-dismiss="modal"
                  style={{ backgroundColor: "#13131a", marginRight: "10px" }}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewRoundModal;
