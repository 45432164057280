import React from "react";
import PropTypes from "prop-types";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import { tableStyles } from "./StyleComponents";
import manuIcon from "../../assets/icons/manu.png";
import { isEmptyOrNull, addCommas, getCurrencySymbol } from "../../helpers";
import _ from "lodash";

function headerCell(manager) {
  const headCells = [];
  headCells.push({
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "NAME",
  });
  if (manager) {
    headCells.push({
      id: "office",
      numeric: false,
      disablePadding: false,
      label: "OFFICE",
    });
  }
  if (!manager) {
    headCells.push({
      id: "balance",
      numeric: false,
      disablePadding: false,
      label: "BALANCE",
    });
  }
  // if (manager) {
  //   headCells.push({
  //     id: "target",
  //     numeric: false,
  //     disablePadding: false,
  //     label: "TARGET",
  //   });
  // }
  headCells.push({
    id: "email",
    numeric: false,
    disablePadding: false,
    label: "EMAIL",
  });
  headCells.push({
    id: "mobile",
    numeric: false,
    disablePadding: false,
    label: "MOBILE",
  });
  headCells.push({
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "STATUS",
  });
  headCells.push({
    id: "actions",
    numeric: false,
    disablePadding: false,
    label: "ACTIONS",
  });

  return headCells;
}
function EnhancedUserTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    manager,
  } = props;

  const headCells = headerCell(manager);
  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          {rowCount > 0 ? (
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{ "aria-label": "select all desserts" }}
            />
          ) : null}
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.id === "actions" ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedUserTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function EnhancedUserTable({
  rows,
  onSelectUser,
  selected,
  setSelected,
  investor,
  modalId,
  onClickAction,
  onFilterTextChange,
  action,
}) {
  const classes = tableStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;
  let searchId = investor ? "search_user" : "search_manager";
  return (
    <Paper className={classes.paper}>
      <div className="row" style={{ padding: "15px 15px 0 15px" }}>
        <div className="col-sm-10 col-xs-9">
          <div className="form-group">
            <input
              style={{ backgroundColor: "#1c1c24" }}
              type="text"
              placeholder={investor ? "Search Investors" : "Search Managers"}
              className="form-control"
              id={searchId}
              autoComplete="off"
              onChange={(e) => onFilterTextChange(e)}
            />
            {/* <div className="icon-addon addon-md">
              <input
                style={{ backgroundColor: "#1c1c24" }}
                type="text"
                placeholder="search"
                className="form-control"
                id={searchId}
                autoComplete="off"
                onChange={(e) => onFilterTextChange(e)}
              />
              <label
                htmlFor={searchId}
                className="glyphicon glyphicon-search"
                rel="tooltip"
              ></label>
            </div> */}
          </div>
        </div>
        <div className="col-sm-2 col-xs-3">
          <select
            className="form-control"
            style={{ backgroundColor: "#1c1c24" }}
            value={action ? action : "Actions"}
            onChange={(e) => onClickAction(e)}
          >
            <option disabled>Actions</option>
            {/* <option>Delete</option> */}
            <option>Activate</option>
            <option>Pause</option>
          </select>
        </div>
      </div>
      <TableContainer>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          aria-label="enhanced table"
        >
          <EnhancedUserTableHead
            classes={classes}
            numSelected={selected.length}
            onSelectAllClick={handleSelectAllClick}
            rowCount={rows ? rows.length : 0}
            manager={investor ? false : true}
          />
          <TableBody>
            {rows
              ? rows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    let symbol = getCurrencySymbol(row.currency);

                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.id}
                        selected={isItemSelected}
                      >
                        <TableCell
                          padding="checkbox"
                          onClick={
                            rows && rows.length > 0
                              ? (event) => handleClick(event, row.id)
                              : null
                          }
                        >
                          <Checkbox
                            checked={isItemSelected}
                            inputProps={{ "aria-labelledby": labelId }}
                          />
                        </TableCell>
                        <TableCell
                          style={{ color: "gray" }}
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                        >
                          {row.first_name} {row.last_name}
                          {investor
                            ? row
                              ? " (" + row.account_number + ")"
                              : ""
                            : ""}
                        </TableCell>
                        {!investor ? (
                          <TableCell style={{ color: "gray" }} align="left">
                            {row.office_number ? "+" + row.office_number : ""}
                          </TableCell>
                        ) : null}
                        {investor ? (
                          <TableCell style={{ color: "gray" }} align="left">
                            {!isEmptyOrNull(row.balance)
                              ? symbol + addCommas(row.balance)
                              : symbol + "0.00"}
                          </TableCell>
                        ) : null}
                        {/* <TableCell style={{ color: "gray" }} align="left">
                          {investor
                            ? !isEmptyOrNull(row.balance)
                              ? symbol + addCommas(row.balance)
                              : symbol + "0.00"
                            : !isEmptyOrNull(row.investors) &&
                              row.investors.length > 0
                            ? symbol +
                              addCommas(_.sumBy(row.investors, "balance"))
                            : symbol + "0.00"}
                        </TableCell> */}
                        {/* {!investor ? (
                          <TableCell style={{ color: "gray" }} align="left">
                            {!isEmptyOrNull(row.target)
                              ? symbol + addCommas(row.target)
                              : symbol + "0.00"}
                          </TableCell>
                        ) : null} */}
                        <TableCell style={{ color: "gray" }} align="left">
                          {row.email}
                        </TableCell>
                        <TableCell style={{ color: "gray" }} align="left">
                          {row.mobile ? "+" + row.mobile : ""}
                        </TableCell>
                        <TableCell align="left">
                          {row.status ? (
                            <span
                              className="status"
                              style={{
                                color:
                                  row.status === "Active"
                                    ? "#27AE60"
                                    : row.status === "Closed"
                                    ? "#C0392B"
                                    : "#FFFF00",
                                backgroundColor:
                                  row.status === "Active"
                                    ? "rgb(34, 153, 84, 0.2)"
                                    : row.status === "Closed"
                                    ? "rgb(169, 50, 38, 0.2)"
                                    : "rgb(255,255,0, 0.2)",
                              }}
                            >
                              {row.status}
                            </span>
                          ) : null}
                        </TableCell>
                        <TableCell style={{ color: "#fff" }} align="right">
                          <img
                            data-toggle="modal"
                            data-target={`#${modalId}`}
                            className="view-user-icon"
                            alt="View Users"
                            src={manuIcon}
                            width="30px"
                            height="30px"
                            onMouseOver={() => onSelectUser(row)}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })
              : null}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 15]}
        component="div"
        count={rows ? rows.length : 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
