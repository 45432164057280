import React from "react";
import InvestmentTable from "./InvestmentsTable";
import NewRoundModal from "./NewRoundModal";
import AddNotesModal from "./AddNotesModal";
import CompleteRoundModal from "./CompleteRoundModal";
import RoundStartModal from "./RoundStartModal";

const InvestmentRounds = ({
  onChange,
  onClickSave,
  data,
  onChangeStatus,
  // isStartedRound,
  onSaveNotes,
  selectedRound,
  selected_round,
  notes,
  onFilterTextChange,
  onRoundComplete,
  sort,
  sortBy,
  investors,
  investor,
  handleChange,
  complete_date,
  onSelectRound,
  pastCompleteDate,
  pastStartDate,
  startDate,
  onClickStart,
  onClickDelete,
}) => {
  return (
    <>
      <div className="row">
        <div className="col-xs-6">
          <div className="comp-header">Investment Rounds</div>
        </div>
        <div className="col-xs-6" style={{ textAlign: "right" }}>
          <button
            className="btn btn-primary"
            data-toggle="modal"
            data-target="#add-round-modal"
            style={{ marginRight: "10px" }}
          >
            Create Investment Round
          </button>
          <div className="btn-group" id="comp-heading-grp-btns">
            <button type="button" className="btn" id="comp-heading-btn-text">
              <span style={{ color: "gray" }}>Sort by:</span> {sortBy}
            </button>
            <button
              id="comp-heading-dropdown-btn"
              type="button"
              className="btn dropdown-toggle"
              data-toggle="dropdown"
            >
              <span className="caret"></span>
            </button>
            <ul className="dropdown-menu  dropdown-menu-right" role="menu">
              <li onClick={() => sort("desc", "Newest")}>
                <a href="#newest">Newest</a>
              </li>
              <li onClick={() => sort("asc", "Oldest")}>
                <a href="#oldest">Oldest</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <InvestmentTable
        onClickDelete={(id) => onClickDelete(id)}
        // isStartedRound={isStartedRound}
        rows={data}
        onChangeStatus={(id, status) => onChangeStatus(id, status)}
        selectedRound={(round) => selectedRound(round)}
        onFilterTextChange={(e) => onFilterTextChange(e)}
        onSelectRound={(data) => onSelectRound(data)}
        onClickStart={(id) => onClickStart(id)}
      />
      <NewRoundModal
        onClickSave={(isPast) => onClickSave(isPast)}
        investors={investors}
        investor={investor}
        handleChange={(v) => handleChange(v)}
        pastStartDate={pastStartDate}
        pastCompleteDate={pastCompleteDate}
        onChange={(e) => onChange(e)}
      />
      <AddNotesModal
        onChange={(e) => onChange(e)}
        onSaveNotes={() => onSaveNotes()}
        selected_round={selected_round}
        notes={notes}
      />
      <CompleteRoundModal
        onChange={(e) => onChange(e)}
        complete_date={complete_date}
        onRoundComplete={() => onRoundComplete()}
      />
      <RoundStartModal
        onChange={(e) => onChange(e)}
        startDate={startDate}
        onChangeStatus={() => onChangeStatus(null, "Started")}
      />
    </>
  );
};

export default InvestmentRounds;
