import React from "react";
import { connect } from "react-redux";
import {
  loaderState,
  getWithDrawals,
  updateWithdrawalStatus,
  sortData,
  selectedWithdrawal,
  getActiveUsers,
  addWithDrawal,
} from "../../actions";
import { transactions as trans } from "../../constants/actionTypes";
import {
  filterData,
  isEmptyOrNull,
  createWithdrawalsData,
} from "../../helpers";
import _ from "lodash";
import WithDrawalComp from "../../components/WithDrawal";
import swal from "sweetalert";
import $ from "jquery";

class WithDrawal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: [],
      sortBy: "Newest",
      filterText: "",
      action: "Actions",
    };
  }

  componentDidMount() {
    this.props.loaderState(true);
    this.props.getWithDrawals();
    this.props.getActiveUsers();
  }

  setSelected = (newSelected) => {
    this.setState({ selected: newSelected });
  };

  updateStatus = (id, status) => {
    swal({
      title: "Are you sure?",
      text: "Once changed, you will not be able to recover this!",
      icon: "warning",
      buttons: ["Cancel", "Update"],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        const { WithDrawals } = this.props;
        let withdrawal = null;
        _.forEach(WithDrawals, (drawal) => {
          if (drawal.transaction_id === id) {
            withdrawal = drawal;
          }
        });
        this.props.loaderState(true);
        this.props.updateWithdrawalStatus([id], status, withdrawal);
        setTimeout(() => {
          this.props.loaderState(false);
        }, 2000);
      }
    });
  };

  sort = (order, sortBy) => {
    this.setState({ sortBy });
    const { WithDrawals } = this.props;
    this.props.sortData(WithDrawals, trans.WITHDRAWALS, "date_time", order);
  };

  onSelectWithdrawal = (withdrawal) => {
    this.props.selectedWithdrawal(withdrawal);
  };

  onClickActions = (action) => {
    this.setState({ action });
    const { WithDrawals } = this.props;
    let selected = [...this.state.selected];
    let status = "";
    let msg = "";
    if (action === "Complete") {
      status = "Completed";
      msg = "Canceled";
    } else {
      status = "Canceled";
      msg = "Completed";
    }
    if (selected.length > 0) {
      let same = true;
      _.forEach(WithDrawals, (drawal) => {
        if (
          selected.includes(drawal.transaction_id) &&
          drawal.status === status
        ) {
          same = false;
        }
      });
      if (same) {
        this.setState({ selected: [] });
        swal({
          title: "Are you sure?",
          text: "You want to update the status!",
          icon: "warning",
          buttons: ["Cancel", "Update"],
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) {
            this.props.loaderState(true);
            // this.props.updateWithdrawalStatus(selected, status);
            _.forEach(selected, (sel) => {
              let withdrawal = null;
              _.forEach(WithDrawals, (drawal) => {
                if (drawal.transaction_id === sel) {
                  withdrawal = drawal;
                }
              });
              this.props.updateWithdrawalStatus([sel], status, withdrawal);
              setTimeout(() => {
                this.props.loaderState(false);
              }, 2000);
            });
            this.setState({ selected: [] });
          }
        });
      } else {
        swal("", "One or more record(s) have the status " + msg);
      }
      this.setState({ action: "Actions" });
    } else {
      this.setState({ action: "Actions" });
      swal("", "Please select a record", "info");
    }
  };

  filter = (array, text) => {
    let filteredData = filterData(array, text);
    return filteredData;
  };

  onFilterTextChange = ({ target }) => {
    this.setState({ filterText: target.value });
  };

  onAddWithDrawal = () => {
    let stateData = { ...this.state };
    if (Number(stateData.selectedInvestor.balance) < Number(stateData.amount)) {
      swal("", "Amount should not greater than your Investor balance", "info");
    } else {
      this.props.loaderState(true);
      this.props.addWithDrawal({
        withdrawl_type: stateData.withdrawl_type,
        currency:
          stateData && stateData.selectedInvestor
            ? stateData.selectedInvestor.currency
            : "GBP",
        amount: stateData.amount,
        account_number: stateData.account_number,
        balance:
          stateData && stateData.selectedInvestor
            ? Number(stateData.selectedInvestor.balance)
            : 0,
      });
      $(".close").click();
    }
  };

  onChange = ({ target }) => {
    const { value, id } = target;
    if (id === "") {
      this.setState({ withdrawl_type: value });
    } else {
      this.setState({ [id]: value });
    }
    if (id === "account_number") {
      const { investors } = this.props;

      _.forEach(investors, (investor) => {
        if (investor.account_number === value) {
          this.setState({ selectedInvestor: investor });
        }
      });
    }
  };

  render() {
    const { WithDrawals, selected_withdrawal, investors } = this.props;
    const { selected, sortBy, filterText, action } = this.state;

    let drawals = WithDrawals ? createWithdrawalsData(WithDrawals) : null;
    let filteredWithdrawals = !isEmptyOrNull(filterText)
      ? this.filter(drawals, filterText)
      : drawals;

    let stateData = { ...this.state };

    return (
      <WithDrawalComp
        stateData={stateData}
        WithDrawals={filteredWithdrawals}
        setSelected={(newSelected) => this.setSelected(newSelected)}
        updateStatus={(id, status) => this.updateStatus(id, status)}
        sort={(order, sortBy) => this.sort(order, sortBy)}
        onSelectWithdrawal={(withdrawal) => this.onSelectWithdrawal(withdrawal)}
        selectedWithdrawal={selected_withdrawal}
        onClickActions={(action) => this.onClickActions(action)}
        onFilterTextChange={(e) => this.onFilterTextChange(e)}
        onChange={(e) => this.onChange(e)}
        investors={investors}
        onAddWithDrawal={() => this.onAddWithDrawal()}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    WithDrawals: state.transactions.withDrawals,
    selected_withdrawal: state.transactions.selected_withdrawal,
    investors: state.users.active_users,
  };
};

export default connect(mapStateToProps, {
  loaderState,
  getWithDrawals,
  updateWithdrawalStatus,
  sortData,
  selectedWithdrawal,
  getActiveUsers,
  addWithDrawal,
})(WithDrawal);
