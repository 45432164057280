import { USER_API } from "../../api";
import { users } from "../../constants/actionTypes";
import swal from "sweetalert";
import { loaderState } from "../index";
import { mergeArrayObjects } from "../../helpers";
import _ from "lodash";

export const getActiveUsers = () => (dispatch) => {
	let filter = {
		where: {
			status: "Active",
		},
		order: "first_name ASC",
	};
	USER_API.get(`/ClientRegistrations?filter=${JSON.stringify(filter)}`)
		.then((res) => {
			let data = res.data;
			// console.log(data);
			if (typeof data !== "string") {
				dispatch({ type: users.ACTIVE_USERS, payload: res.data });
				dispatch(loaderState(false));
			} else {
				dispatch(loaderState(false));
				swal("Oops!", data, "error");
			}
		})
		.catch((err) => {
			dispatch(loaderState(false));
			swal("Oops!", err.message, "error");
		});
};

export const getUsers = () => (dispatch) => {
	let filter = {
		order: "first_name ASC",
	};
	USER_API.get(`/ClientRegistrations?filter=${JSON.stringify(filter)}`)
		.then((res) => {
			let data = res.data;
			if (typeof data !== "string") {
				dispatch({ type: users.ALL_USERS, payload: res.data });
				dispatch(loaderState(false));
			} else {
				dispatch(loaderState(false));
				swal("Oops!", data, "error");
			}
		})
		.catch((err) => {
			dispatch(loaderState(false));
			swal("Oops!", err.message, "error");
		});
};

export const selectedUser = (user) => {
	return { type: users.SELECTED_USER, payload: user };
};

export const getTotalInvestments = () => (dispatch) => {
	let filter = {
		fields: {
			id: false,
			tier: false,
			account_number: false,
			manager_id: false,
		},
	};
	USER_API.get(`/ClientRegistrations?filter=${JSON.stringify(filter)}`)
		.then((res) => {
			dispatch({
				type: users.TOTAL_INVESTOMENT,
				payload: _.sumBy(res.data, "balance"),
			});
			dispatch(loaderState(false));
		})
		.catch((err) => {
			swal("Oops!", err.message, "error");
			dispatch(loaderState(false));
		});
};

export const getInvestorsCount = () => (dispatch) => {
	USER_API.get(`/ClientRegistrations/count`)
		.then((res) => {
			dispatch({
				type: users.TOTAL_INVESTORS,
				payload: res.data.count,
			});
			dispatch(loaderState(false));
		})
		.catch((err) => {
			dispatch(loaderState(false));
			swal("Oops!", err.message, "error");
		});
};

export const updateUser = (ids, data) => (dispatch) => {
	let where = {
		id: { inq: { ...ids } },
	};
	USER_API.patch(`/ClientRegistrations?where=${JSON.stringify(where)}`, {
		...data,
	})
		.then((res) => {
			dispatch(getUsers());
			// let updatedInvestors = [];
			// _.forEach(all_users, (user) => {
			//   if (ids.includes(user.id)) {
			//     updatedInvestors.push({ ...user, ...data });
			//   } else {
			//     updatedInvestors.push(user);
			//   }
			// });
			// dispatch({ type: users.ALL_USERS, payload: updatedInvestors });
			// let action =
			//   status === "Closed"
			//     ? "deleted!"
			//     : status === "Active"
			//     ? "activated!"
			//     : "paused!";
			// swal("User(s) Updated", {
			//   icon: "success",
			// });
			// dispatch(loaderState(false));
		})
		.catch((err) => {
			dispatch(loaderState(false));
			swal("Oops!", err.message, "error");
		});
};

export const getUsersBankDetails = (account_numbers, data, type) => (dispatch) => {
	// let where = {
	//   inq: account_numbers,
	// };
	//USER_API.get(`/ClientBankingdetailsDomestic?where=${JSON.stringify(where)}`)
	USER_API.get(`/ClientBankingdetailsDomestic`)
		.then((res) => {
			let mergedData = mergeArrayObjects(data, res.data, "account_number", "bankDetails");
			dispatch({ type, payload: mergedData });
			dispatch(loaderState(false));
		})
		.catch((err) => {
			swal("Oops!", err.message, "error");
		});
};

export const addInvestor = (data) => (dispatch, getState) => {
	const { all_managers } = getState().managers;
	if (data.manager_id) {
		_.forEach(all_managers, (manager) => {
			if (data.manager_id === manager.id) {
				data.location = manager.office_location;
				// if (manager.office_location === "uk") {
				//   data.currency = "GBP";
				// } else if (manager.office_location === "turkey") {
				//   data.currency = "LIRA";
				// } else if (manager.office_location === "dubai") {
				//   data.currency = "AED";
				// } else if (manager.office_location === "russia") {
				//   data.currency = "RUB";
				// } else if (manager.office_location === "france") {
				//   data.currency = "EUR";
				// } else if (manager.office_location === "kazakhstan") {
				//   data.currency = "KZT";
				// } else {
				//   data.currency = "USD";
				// }
			}
		});
	}
	USER_API.post("/ClientRegistrations/signup", data)
		.then((res) => {
			dispatch(getUsers());
			swal("", "Investor added", "success");
		})
		.catch((err) => {
			dispatch(loaderState(false));
			swal("Oops!", err.message, "error");
		});
};

export const updateInvestor = (id, data) => (dispatch, getState) => {
	const { all_managers } = getState().managers;
	if (data.manager_id) {
		_.forEach(all_managers, (manager) => {
			if (data.manager_id === manager.id) {
				data.location = manager.office_location;
				// if (manager.office_location === "uk") {
				//   data.currency = "GBP";
				// } else if (manager.office_location === "turkey") {
				//   data.currency = "LIRA";
				// } else if (manager.office_location === "dubai") {
				//   data.currency = "AED";
				// } else if (manager.office_location === "russia") {
				//   data.currency = "RUB";
				// } else if (manager.office_location === "france") {
				//   data.currency = "EUR";
				// } else if (manager.office_location === "kazakhstan") {
				//   data.currency = "KZT";
				// } else {
				//   data.currency = "USD";
				// }
			}
		});
	}
	// const { all_users } = getState().users;
	USER_API.patch(`/ClientRegistrations/${id}`, data)
		.then((res) => {
			// let storeData = [];
			dispatch(getUsers());
			// _.forEach(all_users, (user) => {
			//   if (user.id === id) {
			//     storeData.push({ ...user, ...data });
			//   } else {
			//     storeData.push(user);
			//   }
			// });
			// dispatch({ type: users.ALL_USERS, payload: storeData });
			dispatch(loaderState(false));
		})
		.catch((err) => {
			dispatch(loaderState(false));
			swal("Oops!", err.message, "error");
		});
};
