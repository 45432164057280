import React from "react";

const AddNotesModal = ({ onChange, onSaveNotes, selected_round, notes }) => {
  return (
    <div id="add-notes-modal" className="modal fade" role="dialog">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <button type="button" className="close" data-dismiss="modal">
              &times;
            </button>
            <h4 className="modal-title">Add Notes</h4>
          </div>
          <div className="modal-body">
            <div className="form-group">
              {/* <label htmlFor="notes">Round Name</label> */}
              <textarea
                autoComplete="off"
                onChange={(e) => onChange(e)}
                type="text"
                rows={15}
                value={
                  notes && notes !== null
                    ? notes
                    : selected_round && selected_round.notes
                    ? selected_round.notes
                    : ""
                }
                style={{ resize: "none" }}
                placeholder="write your notes here!"
                className="form-control"
                id="notes"
              />
            </div>
          </div>
          <div className="modal-footer">
            <button className="btn btn-primary" onClick={() => onSaveNotes()}>
              Save
            </button>
            <button
              type="button"
              className="btn"
              data-dismiss="modal"
              style={{ backgroundColor: "#13131a", marginRight: "10px" }}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddNotesModal;
