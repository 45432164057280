import React from "react";
import { Route, Switch } from "react-router-dom";

//containers
import Dashboard from "../containers/Dashboard";
import Users from "../containers/Users";
import Transactions from "../containers/Transactions";
import WithDrawal from "../containers/WithDrawal";
import Managers from "../containers/Managers";
import InvestmentRounds from "../containers/InvestmentRounds";
import Documents from "../containers/documents";

const Routes = () => {
  return (
    <Switch>
      <Route path="/dashboard" component={Dashboard} />
      <Route path="/investors" component={Users} />
      <Route path="/transactions" component={Transactions} />
      <Route path="/withdrawal" component={WithDrawal} />
      <Route path="/managers" component={Managers} />
      <Route path="/investmentrounds" component={InvestmentRounds} />
      <Route path="/documents" component={Documents} />
    </Switch>
  );
};

export default Routes;
