export const loggedUser = {
  UERS_INFO: "UERS_INFO",
};

export const common = {
  LOADER: "LOADER",
  SELECTED_URL: "SELECTED_URL",
  COUNTRIES_DATA: "COUNTRIES_DATA",
};

export const transactions = {
  ALL_TRANSACTIONS: "ALL_TRANSACTIONS",
  DASHBOARD_TRANSACTIONS: "DASHBOARD_TRANSACTIONS",
  WITHDRAWALS: "WITHDRAWALS",
  TRANSACTIONS_COUNT: "TRANSACTIONS_COUNT",
  WITHDRAWALS_COUNT: "WITHDRAWALS_COUNT",
  SELECTED_WITHDRAWAL: "SELECTED_WITHDRAWAL",
};

export const users = {
  SELECTED_USER: "SELECTED_USER",
  ALL_USERS: "ALL_USERS",
  ACTIVE_USERS: "ACTIVE_USERS",
  TOP_INVESTERS: "TOP_INVESTERS",
  TOTAL_INVESTOMENT: "TOTAL_INVESTOMENT",
  TOTAL_INVESTORS: "TOTAL_INVESTORS",
};

export const managers = {
  ALL_MANAGERS: "ALL_MANAGERS",
  TOP_MANAGERS: "TOP_MANAGERS",
  SELECTED_MANAGER: "SELECTED_MANAGER",
};

export const rounds = {
  ROUNDS: "ROUNDS",
  SELECTED_ROUND: "SELECTED_ROUND",
};

export const companyInfo = {
  COMPANY_DETAILS: "COMPANY_DETAILS",
};

export const documents = {
  ALL_DOCUMENTS: "ALL_DOCUMENTS",
  SELECTED_DOCUMENT: "SELECTED_DOCUMENT",
};
