import React from "react";
import moment from "moment";

export default function RoundStartModal({
  onChange,
  startDate,
  onChangeStatus,
}) {
  let maxDate = moment().format("YYYY-MM-DD");
  return (
    <div id="start-round-modal" className="modal fade" role="dialog">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <button type="button" className="close" data-dismiss="modal">
              &times;
            </button>
            <h4 className="modal-title">Start Round</h4>
          </div>
          <div className="modal-body">
            <div className="form-group">
              <label htmlFor="complete_date">Start Date</label>
              <input
                onChange={(e) => onChange(e)}
                className="form-control"
                type="date"
                id="startDate"
                value={moment(startDate).format("YYYY-MM-DD")}
                max={maxDate}
              />
            </div>
          </div>
          <div className="modal-footer">
            <button
              className="btn btn-primary"
              onClick={() => onChangeStatus()}
            >
              Save
            </button>
            <button
              type="button"
              className="btn"
              data-dismiss="modal"
              style={{ backgroundColor: "#13131a", marginRight: "10px" }}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
