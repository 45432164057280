import React from "react";
import { addCommas } from "../../helpers";
import Card from "../Common/Card";
import Balance from "./Balance";
import Investors from "./Investors";
import Managers from "./Managers";
import TopManager from "./TopManager";
import TransactionHistory from "./TransactionHistory";
import TopInvestors from "./TopInvestors";
import Tabs from "../Common/tabs";
import OfficeBalance from "./OfficeBalance";

export default function Dashboard({
  balance,
  investors,
  managers,
  goto,
  topInvestors,
  totalInvestment,
  totalInvestors,
  activeUsers,
  transactions,
  topManagers,
  startedRounds,
  companyDetails,
}) {
  console.log(balance)
  let GBP = balance.datasets[0].data.reduce(function (a, b) {
    return Number(a) + Number(b);
  }, 0);
  GBP = Number(GBP).toFixed(2);

  let LIRA = balance.datasets[1].data.reduce(function (a, b) {
    return Number(a) + Number(b);
  }, 0);
  LIRA = Number(LIRA).toFixed(2);

  let KZT = balance.datasets[3].data.reduce(function (a, b) {
    return Number(a) + Number(b);
  }, 0);
  KZT = Number(KZT).toFixed(2);

  let AED = balance.datasets[4].data.reduce(function (a, b) {
    return Number(a) + Number(b);
  }, 0);
  AED = Number(AED).toFixed(2);

  let RUB = balance.datasets[5].data.reduce(function (a, b) {
    return Number(a) + Number(b);
  }, 0);
  RUB = Number(RUB).toFixed(2);

  let EUR = balance.datasets[6].data.reduce(function (a, b) {
    return Number(a) + Number(b);
  }, 0);
  EUR = Number(EUR).toFixed(2);

  let USD = balance.datasets[2].data.reduce(function (a, b) {
    return Number(a) + Number(b);
  }, 0);
  USD = Number(USD).toFixed(2);

  return (
    <>
      <div className="comp-header">Dashboard</div>
      <div className="row">
        <div className="col-md-2">
          <Card>
            <div className="dashboard-card-title">GBP</div>
            <h3>£ {addCommas(GBP)}</h3>
          </Card>
        </div>
        <div className="col-md-2">
          <Card>
            <div className="dashboard-card-title">TURKISH LIRA</div>
            <h3>₺ {addCommas(LIRA)}</h3>
          </Card>
        </div>
        <div className="col-md-2">
          <Card>
            <div className="dashboard-card-title">KZT</div>
            <h3>₸ {addCommas(KZT)}</h3>
          </Card>
        </div>
        <div className="col-md-2">
          <Card>
            <div className="dashboard-card-title">AED</div>
            <h3>د.إ {addCommas(AED)}</h3>
          </Card>
        </div>
        <div className="col-md-2">
          <Card>
            <div className="dashboard-card-title">RUB</div>
            <h3>₽ {addCommas(RUB)}</h3>
          </Card>
        </div>
        <div className="col-md-2">
          <Card>
            <div className="dashboard-card-title">USD</div>
            <h3>$ {addCommas(USD)}</h3>
          </Card>
        </div>
      </div>
      <div className="row">
        {/* <div className="col-lg-3">
          <Card>
            <div className="dashboard-card-title">Total Investments</div>
            <h3>£{addCommas(totalestment) : 0}</h3>
          </Card>
        </div> */}
        {/* <div className="col-lg-4">
          <Card>
            <div className="dashboard-card-title">Company Income</div>
            <h3>
              £{" "}
              {companyDetails && companyDetails.dr_profit
                ? addCommas(Number(companyDetails.dr_profit).toFixed(2))
                : "0.00"}
            </h3>
          </Card>
        </div> */}
        <div className="col-md-4">
          <Card>
            <div className="dashboard-card-title">EUR</div>
            <h3>€ {addCommas(EUR)}</h3>
          </Card>
        </div>
        <div className="col-lg-4">
          <Card>
            <div className="dashboard-card-title">Live Investment Rounds</div>
            <h3>
              {
                startedRounds ? startedRounds : 0
                // startedRound
                //   ? Number(moment(startedRound.start_date).format("D")) +
                //     moment().daysInMonth() -
                //     1 -
                //     Number(moment().format("D")) +
                //     " (days remining)"
                //   : "NA"
              }
            </h3>
          </Card>
        </div>
        <div className="col-lg-4">
          <Card>
            <div className="dashboard-card-title">Current Investor Number</div>
            <h3>
              {activeUsers ? addCommas(activeUsers.length).split(".")[0] : 0}
            </h3>
          </Card>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <Card>
            <Tabs
              tabs={[
                "Balance Per Currency",
                "Investors Balance",
                "Investors Per Manager",
              ]}
              className="tabs"
            />
            <div className="tab-content">
              <div id="BalancePerCurrency" className="tab-pane active">
                <div style={{ maxHeight: "500px" }}>
                  <Balance data={balance} />
                </div>
              </div>
              <div id="InvestorsBalance" className="tab-pane fade">
                <div style={{ maxHeight: "400px" }}>
                  <Investors data={investors} />
                </div>
              </div>
              <div id="InvestorsPerManager" className="tab-pane fade">
                <div style={{ maxHeight: "400px" }}>
                  <Managers data={managers} />
                </div>
              </div>
              {/* <div id="OfficeBalance" className="tab-pane fade">
                <div style={{ maxHeight: "400px" }}>
                  <OfficeBalance />
                </div>
              </div> */}
            </div>
          </Card>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6">
          <Card>
            <div className="dashboard-card-title">Top Managers</div>
            <TopManager topManagers={topManagers} goto={(path) => goto(path)} />
          </Card>
        </div>
        <div className="col-lg-6">
          <Card>
            <div className="dashboard-card-title">
              Top Investors
              {/* <img src={manuIcon} width="30" /> */}
            </div>
            <TopInvestors
              topInvestors={topInvestors}
              goto={(path) => goto(path)}
            />
          </Card>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <Card>
            <div className="dashboard-card-title">Transaction History</div>
            <TransactionHistory
              goto={(path) => goto(path)}
              transactions={transactions}
            />
          </Card>
        </div>
      </div>
    </>
  );
}
