import { documents } from "../../constants/actionTypes";
import { USER_API } from "../../api";
import _ from "lodash";
import { loaderState } from "../common";
import swal from "sweetalert";
import moment from "moment";
import $ from "jquery";

export const getDocuments = () => (dispatch) => {
  USER_API.get(`/clientDocumentDetails`)
    .then((res) => {
      dispatch(loaderState(false));
      dispatch({ type: documents.ALL_DOCUMENTS, payload: res.data });
    })
    .catch((err) => {
      dispatch(loaderState(false));
      swal("Oops!", err.message, "error");
    });
};

export const rejectDocument = (id) => (dispatch) => {
  USER_API.delete(`/clientDocumentDetails/${id}`)
    .then((res) => {
      dispatch(getDocuments());
    })
    .catch((err) => {
      dispatch(loaderState(false));
      swal("Oops!", err.message, "error");
    });
};

export const verifyDocument = (id) => (dispatch) => {
  USER_API.patch(`/clientDocumentDetails/${id}`, { status: "Verified" })
    .then((res) => {
      dispatch(getDocuments());
    })
    .catch((err) => {
      dispatch(loaderState(false));
      swal("Oops!", err.message, "error");
    });
};
