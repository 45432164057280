import React from "react";
import { connect } from "react-redux";
import { removeNullValues, isEmptyOrNull, filterData } from "../../helpers";
import { managers as managerTypes } from "../../constants/actionTypes";
import {
  getManagers,
  loaderState,
  selectedManager,
  sortData,
  updateManagerStatus,
  addManager,
  updateManager,
} from "../../actions";
import _ from "lodash";
import UsersComp from "../../components/Users";
import swal from "sweetalert";
import $ from "jquery";
import moment from "moment";

class Managers extends React.Component {
  constructor(props) {
    super(props);

    this.state = { selected: [], sortBy: "Newest", perPage: 10, activePage: 1 };
  }

  componentDidMount() {
    this.props.loaderState(true);
    this.props.getManagers();
  }

  resetState = () => {
    this.setState({
      first_name: null,
      last_name: null,
      email: null,
      mobile: null,
      password: null,
      confirmPassword: null,
      status: null,
      office_number: null,
      office_location: null,
      target: null,
    });
  };

  setSelected = (selected) => {
    this.setState({ selected });
  };

  onClickAddUser = () => {
    this.setState({ edit: false });
    this.props.selectedManager(null);
  };

  onSelectManager = (manager) => {
    this.props.selectedManager(manager);
  };

  onClickEdit = (manager) => {
    this.setState({ edit: true });
    this.props.selectedManager(manager);
  };

  addOrUpdate = () => {
    let state = this.state;
    let data = {
      first_name: state.first_name,
      last_name: state.last_name,
      email: state.email,
      mobile: state.mobile,
      password: state.password,
      confirmPassword: state.confirmPassword,
      status: state.status,
      office_number: state.office_number,
      office_location: state.office_location,
      target: state.target,
    };

    data = removeNullValues(data);

    if (state.edit) {
      if (JSON.stringify(data) !== "{}") {
        const { selected_manager } = this.props;
        if (data.target && Number(data.target) >= 0) {
          this.resetState();
          this.props.loaderState(true);
          this.props.updateManager(selected_manager.id, data);
          $(".close").click();
        } else {
          swal("", "Target should not have a negative value", "error");
        }
      }
    } else if (
      isEmptyOrNull(data.first_name) ||
      isEmptyOrNull(data.last_name) ||
      isEmptyOrNull(data.password) ||
      isEmptyOrNull(data.confirmPassword) ||
      isEmptyOrNull(data.mobile) ||
      isEmptyOrNull(data.email)
    ) {
      swal(
        "",
        "First Name, Last Name, Email, Mobile, Password and Confirm Password are the required fields",
        "info"
      );
    } else {
      if (data.password !== data.confirmPassword) {
        swal("", `Password and confirm password doesn't match`, "error");
      } else {
        delete data.confirmPassword;
        data.date_time = moment().format();
        this.resetState();
        this.props.loaderState(true);
        this.props.addManager(data);
        $(".close").click();
      }
    }
  };

  onChangePhoneNumber = (value, data, event, state) => {
    this.setState({
      [state]: value,
    });
    if (state === "mobile") {
      this.setState({
        account_number: data.countryCode.toUpperCase(),
      });
    }
  };

  onClickAction = (manager, e) => {
    let selected = this.state.selected;
    let ids = [];
    let status = "";
    let action = "";
    let update = false;
    if (manager) {
      ids = [manager.id];
      status = "Closed";
      update = true;
    }
    if (e) {
      if (selected.length > 0) {
        ids = [...selected];
        update = true;
        action = e.target.value;
        this.setState({ action });
        status =
          action === "Delete"
            ? "Closed"
            : action === "Activate"
            ? "Active"
            : "Paused";
      } else {
        update = false;
      }
    }
    if (update) {
      swal({
        title: "Are you sure?",
        text: "You want to update the status!",
        icon: "warning",
        buttons: ["Cancel", "Update"],
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          this.props.loaderState(true);
          this.props.updateManagerStatus(ids, status);
          this.setState({ selected: [] });
          this.setState({ action: "Actions" });
        }
      });
    } else {
      this.setState({ action: "Actions" });
      swal("", "Please select records", "info");
    }
  };

  sort = (order, sortBy) => {
    this.setState({ sortBy });
    const { managers } = this.props;
    this.props.sortData(
      managers,
      managerTypes.ALL_MANAGERS,
      "date_time",
      order
    );
  };

  handlePageChange = (value) => {
    this.setState({ activePage: value });
  };

  onChange = ({ target }) => {
    const { id, value } = target;
    if (id === "status") {
      if (value === "Delete") {
        this.setState({ [id]: "Closed" });
      } else {
        this.setState({ [id]: value });
      }
    } else {
      this.setState({ [id]: value });
    }
  };

  filter = (array, text) => {
    let filteredData = filterData(array, text);
    return filteredData;
  };

  onFilterTextChange = ({ target }) => {
    this.setState({ filterText: target.value });
  };

  onClickClose = () => {
    this.resetState();
    $(".close").click();
  };

  getInvestors = () => {
    const { selected_manager } = this.props;
    let investors = selected_manager.investors;

    const { perPage, activePage } = this.state;
    let lastIndex = Number(perPage) * Number(activePage);
    let startIndex = lastIndex - Number(perPage);

    return {
      investorsData: investors.slice(startIndex, lastIndex),
      investotsCount: investors.length,
    };
  };

  render() {
    const {
      selected,
      sortBy,
      activePage,
      perPage,
      edit,
      filterText,
      action,
    } = this.state;
    const { managers, selected_manager } = this.props;

    let stateData = { ...this.state };

    let filteredmanagers = !isEmptyOrNull(filterText)
      ? this.filter(managers, filterText)
      : managers;

    let { investorsData, investotsCount } =
      selected_manager && selected_manager.investors ? this.getInvestors() : [];

    return (
      <UsersComp
        edit={edit}
        onClickClose={() => this.onClickClose()}
        resetState={() => this.resetState()}
        users={filteredmanagers}
        heading="Managers"
        selected={selected}
        stateData={stateData}
        setSelected={(newSelected) => this.setSelected(newSelected)}
        onClickAddUser={() => this.onClickAddUser()}
        onChange={(e) => this.onChange(e)}
        onSelectUser={(manager) => this.onSelectManager(manager)}
        onClickEdit={(manager) => this.onClickEdit(manager)}
        addOrUpdate={() => this.addOrUpdate()}
        onClickAction={(manager, e) => this.onClickAction(manager, e)}
        activePage={activePage}
        perPage={perPage}
        handlePageChange={(value) => this.handlePageChange(value)}
        modalId="viewManagerModal"
        selectedUser={selected_manager}
        sort={(order, sortBy) => this.sort(order, sortBy)}
        sortBy={sortBy}
        onChangePhoneNumber={(value, data, event, state) =>
          this.onChangePhoneNumber(value, data, event, state)
        }
        onFilterTextChange={(e) => this.onFilterTextChange(e)}
        action={action}
        investorsData={investorsData}
        investotsCount={investotsCount}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    managers: state.managers.all_managers,
    selected_manager: state.managers.selected_manager,
  };
};

export default connect(mapStateToProps, {
  getManagers,
  loaderState,
  selectedManager,
  sortData,
  updateManagerStatus,
  addManager,
  updateManager,
})(Managers);
