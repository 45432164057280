import React from "react";
import avatar from "../../assets/icons/avatar.png";
// import notification_bell_icon from "../../assets/icons/notifications_bell_icon.png";
import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone";
import Badge from "@material-ui/core/Badge";

export default function Header({ user, goto }) {
  return (
    <div className="app-header">
      <div className="row dropdown">
        <div className="col-lg-2 col-md-2 col-sm-1 col-1"></div>
        <div className="col-lg-7 col-md-7 col-sm-5 col-5" id="user-name"></div>
        <div className="col-lg-3 col-md-3 col-sm-6 col-6">
          {/* <a style={{ marginRight: "15px" }}>GB1234567</a> */}
          <span href="#" style={{ marginRight: "15px" }}>
            {/* <i className="fa fa-bell"></i> */}
            <Badge badgeContent={4} color="primary">
              <NotificationsNoneIcon />
            </Badge>
            {/* <img src={notification_bell_icon} height="10" width="10" /> */}
          </span>
          <span href="#" className="user-info dropdown">
            <img
              src={avatar}
              width="25"
              height="25"
              style={{ borderRadius: "50%" }}
            />
            {user ? user.first_name : ""}
            {user ? " " + user.last_name : ""}
            <i
              className="fa fa-caret-down dropdown-toggle"
              data-toggle="dropdown"
              aria-hidden="true"
            ></i>
            <ul className="dropdown-menu dropdown-menu-right">
              <li>
                <a href="#">Profile</a>
              </li>
              <li>
                <a onClick={() => goto("/")}>Logout</a>
              </li>
            </ul>
          </span>
        </div>
      </div>
    </div>
  );
}
