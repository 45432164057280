import React from "react";
import { connect } from "react-redux";
import {
  getRounds,
  addRound,
  loaderState,
  updateInvestmentRound,
  onSelectedRound,
  completedRound,
  sortData,
  getActiveUsers,
  addPastRound,
  deteleRound,
} from "../../actions";
import _ from "lodash";
import { rounds as ronds } from "../../constants/actionTypes";
import InvestmentRoundsComp from "../../components/InvestmentRounds";
import { isEmptyOrNull, createRoundsData, filterData } from "../../helpers";
import swal from "sweetalert";
import $ from "jquery";
import moment, { months } from "moment";

class InvestmentRounds extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sortBy: "Newest",
      investor: null,
      complete_date: moment(),
      round: null,
    };
  }

  componentDidMount() {
    this.props.loaderState(true);
    this.props.getActiveUsers();
    this.props.getRounds();
  }

  onChange = ({ target }) => {
    const { id, value } = target;
    this.setState({ [id]: value });
  };

  onSelectRound = (round) => {
    this.setState({ round });
  };

  onClickSave = (isPast) => {
    const { investor, pastStartDate, pastCompleteDate } = this.state;
    if (isPast) {
      if (
        !isEmptyOrNull(investor) &&
        !isEmptyOrNull(pastStartDate) &&
        !isEmptyOrNull(pastCompleteDate)
      ) {
        this.props.loaderState(true);
        let data = {
          round_name:
            investor.value +
            "-" +
            moment().format("YY") +
            moment().format("MM") +
            moment().format("DD"),
          account_number: investor.value,
          start_date: moment(pastStartDate),
          complete_date: moment(pastCompleteDate),
          status: "Completed",
        };

        this.props.addPastRound(data);
        this.setState({ investor: null });
        $(".close").click();
      } else {
        swal("", "All fields are required", "info");
      }
    } else {
      if (!isEmptyOrNull(investor)) {
        this.props.loaderState(true);
        this.props.addRound({
          round_name:
            investor.value +
            "-" +
            moment().format("YY") +
            moment().format("MM") +
            moment().format("DD"),
          acount_number: investor.value,
        });
        this.setState({ investor: null });
        $(".close").click();
      } else {
        swal("", "All fields are required", "info");
      }
    }
  };

  onChangeStatus = (rowId, status) => {
    const { startDate, id } = this.state;
    if (!rowId) {
      rowId = id;
      $(".close").click();
    }
    this.props.loaderState(true);
    this.props.updateInvestmentRound(rowId, { status, start_date: startDate });
  };

  selectedRound = (round) => {
    this.props.onSelectedRound(round);
  };

  onSaveNotes = () => {
    const { selected_round } = this.props;
    let notes = this.state.notes;
    this.setState({ notes: null });
    this.props.loaderState(true);
    this.props.updateInvestmentRound(selected_round.id, { notes });
    $(".close").click();
  };

  // isStartedRound = (rounds) => {
  //   let isStarted = false;
  //   _.forEach(rounds, (round) => {
  //     if (round.status === "Started") {
  //       isStarted = true;
  //     }
  //   });

  //   return isStarted;
  // };

  filter = (array, text) => {
    let filteredData = filterData(array, text);
    return filteredData;
  };

  onFilterTextChange = ({ target }) => {
    this.setState({ filterText: target.value });
  };

  onRoundComplete = () => {
    const { complete_date, round } = this.state;
    let roundData = {
      round_name: round.round_name,
      investor_account_id: round.account_number,
      complete_date: moment(complete_date),
    };

    this.props.loaderState(true);
    this.props.completedRound(roundData);
    // swal({
    //   title: "Are you sure?",
    //   text: "You want to complete this round!",
    //   icon: "warning",
    //   buttons: {
    //     cancel: "No",
    //     catch: {
    //       text: "Yes",
    //       value: "Yes",
    //     },
    //   },
    //   dangerMode: true,
    // }).then((value) => {
    //   switch (value) {
    //     case "Yes":

    //     default:
    //   }
    // });
  };

  sort = (order, sortBy) => {
    this.setState({ sortBy });
    const { rounds } = this.props;
    this.props.sortData(rounds, ronds.ROUNDS, "start_date", order);
  };

  getInvestorsData = (investors) => {
    let data = [];
    _.forEach(investors, (investor) => {
      data.push({
        value: investor.account_number,
        label:
          investor.first_name +
          " " +
          investor.last_name +
          " (" +
          investor.account_number +
          ")",
      });
    });

    return data;
  };

  handleChange = (newValue) => {
    this.setState({ investor: newValue });
  };

  onClickStart = (id) => {
    this.setState({ id });
  };

  onClickDelete = (round) => {
    if (round.status === "Completed") {
      swal("", "Please contact customer services", "info");
    } else {
      swal({
        title: "Are you sure?",
        text: "You want to delete this record!",
        icon: "warning",
        buttons: ["Cancel", "Delete"],
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          this.props.loaderState(true);
          this.props.deteleRound(round);
        }
      });
    }
  };

  render() {
    console.log(this.state);
    const { rounds, selected_round, investors } = this.props;
    let data = rounds ? createRoundsData(rounds) : [];
    const {
      notes,
      filterText,
      sortBy,
      investor,
      complete_date,
      pastStartDate,
      pastCompleteDate,
      startDate,
    } = this.state;

    // let isStartedRound = rounds ? this.isStartedRound(rounds) : false;

    let filteredRounds = !isEmptyOrNull(filterText)
      ? this.filter(data, filterText)
      : data;

    filteredRounds = _.orderBy(filteredRounds, ["remainingDays"], ["asc"]);

    let investorsData =
      investors && investors.length >= 0
        ? this.getInvestorsData(investors)
        : null;
    let newinvestorsData = _.orderBy(investorsData, ["label"], ["asc"]);

    return (
      <InvestmentRoundsComp
        data={filteredRounds}
        // isStartedRound={isStartedRound}
        onChange={(e) => this.onChange(e)}
        onClickSave={(isPast) => this.onClickSave(isPast)}
        onChangeStatus={(id, status) => this.onChangeStatus(id, status)}
        onSaveNotes={() => this.onSaveNotes()}
        selectedRound={(round) => this.selectedRound(round)}
        selected_round={selected_round}
        notes={notes}
        onFilterTextChange={(e) => this.onFilterTextChange(e)}
        onRoundComplete={(data) => this.onRoundComplete(data)}
        sort={(order, sortBy) => this.sort(order, sortBy)}
        sortBy={sortBy}
        investors={investorsData}
        investor={investor}
        handleChange={(v) => this.handleChange(v)}
        complete_date={complete_date}
        onSelectRound={(round) => this.onSelectRound(round)}
        pastStartDate={pastStartDate}
        pastCompleteDate={pastCompleteDate}
        startDate={startDate}
        onClickStart={(id) => this.onClickStart(id)}
        onClickDelete={(id) => this.onClickDelete(id)}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    rounds: state.rounds.all_rounds,
    selected_round: state.rounds.selected_round,
    investors: state.users.active_users,
  };
};

export default connect(mapStateToProps, {
  addRound,
  getRounds,
  loaderState,
  updateInvestmentRound,
  onSelectedRound,
  completedRound,
  sortData,
  getActiveUsers,
  addPastRound,
  deteleRound,
})(InvestmentRounds);
