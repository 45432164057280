import React from "react";
import { connect } from "react-redux";
import { selectedNav } from "../../actions";
import { goto } from "../../helpers";
import SignUpComp from "../../components/SignUp";

class SignUp extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  onClickSignUp = () => {
    this.props.selectedNav("/dashboard");
    goto("/dashboard");
  };

  render() {
    return <SignUpComp onClickSignUp={() => this.onClickSignUp()} />;
  }
}

export default connect(null, { selectedNav })(SignUp);
