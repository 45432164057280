import { combineReducers } from "redux";

import commonReducer from "./common";
import transactionsReducer from "./transactions";
import usersReducer from "./users";
import managersReducer from "./managers";
import authReducer from "./auth";
import roundsReducer from "./rounds";
import documentsReducer from "./documents";

export default combineReducers({
  common: commonReducer,
  transactions: transactionsReducer,
  users: usersReducer,
  managers: managersReducer,
  auth: authReducer,
  rounds: roundsReducer,
  documents: documentsReducer,
});
