import axios from "axios";
import { USER_API_URL, ADMIN_API_URL } from "../constants/global-constants";

export const USER_API = axios.create({
  baseURL: USER_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

export const ADMIN_API = axios.create({
  baseURL: ADMIN_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});
