import React from "react";
import { isEmptyOrNull, addCommas, getCurrencySymbol } from "../../helpers";
import TablePagination from "../Common/Pagination";

const ManagersInvestors = ({
  investors,
  activePage,
  perPage,
  totalPages,
  handlePageChange,
}) => {
  console.log(investors);
  return (
    <>
      <div className="table-responsive">
        <div className="transaction-data">
          <table className="table">
            <thead>
              <tr>
                <td>NAME</td>
                <td>BALANCE</td>
                <td>EMAIL</td>
                <td>MOBILE</td>
                <td>STATUS</td>
              </tr>
            </thead>
            <tbody>
              {investors
                ? investors.map((investor) => (
                    <tr key={investor.id}>
                      <td>
                        {investor.first_name} {investor.last_name}
                        {investor
                          ? investor
                            ? " (" + investor.account_number + ")"
                            : ""
                          : ""}
                      </td>
                      <td>
                        {!isEmptyOrNull(investor.balance)
                          ? `${getCurrencySymbol(
                              investor.currency
                            )} ${addCommas(investor.balance)}`
                          : `${getCurrencySymbol(investor.currency)} 0.00`}
                      </td>
                      <td>{investor.email}</td>
                      <td>+{investor.mobile}</td>
                      <td>
                        {investor.status ? (
                          <span
                            className="status"
                            style={{
                              color:
                                investor.status === "Active"
                                  ? "#27AE60"
                                  : investor.status === "Closed"
                                  ? "#C0392B"
                                  : "#FFFF00",
                              backgroundColor:
                                investor.status === "Active"
                                  ? "rgb(34, 153, 84, 0.2)"
                                  : investor.status === "Closed"
                                  ? "rgb(169, 50, 38, 0.2)"
                                  : "rgb(255,255,0, 0.2)",
                            }}
                          >
                            {investor.status}
                          </span>
                        ) : null}
                      </td>
                    </tr>
                  ))
                : null}
            </tbody>
          </table>
        </div>
      </div>
      {investors && investors.length > 0 ? (
        <div style={{ textAlign: "center" }}>
          <TablePagination
            activePage={activePage}
            perPage={perPage}
            totalPages={totalPages}
            handlePageChange={handlePageChange}
          />
        </div>
      ) : null}
      {investors && investors.length > 0 ? null : (
        <div className="no-data">
          <i>No data found...</i>
        </div>
      )}
    </>
  );
};

export default ManagersInvestors;
