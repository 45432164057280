import React from "react";
import Icon from "@material-ui/core/Icon";
import userIcon from "../../assets/icons/avatar.png";
import Tabs from "../Common/tabs";
import { isEmptyOrNull, addCommas, getProgress } from "../../helpers";
import ManagersInvestors from "./ManagersInvestors";
import _ from "lodash";

const ViewManagerModal = ({
  manager,
  onClickDelete,
  onClickEdit,
  activePage,
  perPage,
  handlePageChange,
  investorsData,
  investotsCount,
}) => {
  let balance = manager
    ? !isEmptyOrNull(manager.investors) && manager.investors.length > 0
      ? _.sumBy(manager.investors, "balance")
      : 0
    : 0;

  let target = manager
    ? !isEmptyOrNull(manager.target) > 0
      ? manager.target
      : 0
    : 0;
  let progress = getProgress(balance, target);
  let remaining = 100 - progress;
  return (
    <div className="modal fade" id="viewManagerModal" role="dialog">
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <button type="button" className="close" data-dismiss="modal">
              <Icon className="fa fa-times" />
            </button>
            <div className="modal-title">
              <div className="media">
                <div className="media-left">
                  <img
                    src={manager && manager.image ? manager.image : userIcon}
                    width="50"
                    height="50"
                  />
                </div>
                <div className="media-body">
                  <h5 className="media-heading" style={{ marginTop: "5px" }}>
                    {manager ? manager.first_name + " " : ""}
                    {manager ? manager.last_name : ""}
                  </h5>
                  <p style={{ color: "gray" }}>
                    Manager ID: #{manager ? manager.id : ""}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="modal-body" style={{ margin: "0 15px " }}>
            <Tabs
              tabs={["Details", "Investors"]} //Performance
              className="tabs"
            />
            <div className="tab-content">
              <div id="Details" className="tab-pane active">
                <div className="user-info">
                  <div>
                    <div className="user-label">Email</div>
                    <div className="label-value">
                      {manager ? manager.email : "NA"}
                    </div>
                  </div>
                  <div>
                    <div className="user-label">Phone</div>
                    <div className="label-value">
                      {manager ? "+" + manager.mobile : "NA"}
                    </div>
                  </div>
                </div>
                <hr />
                <div className="user-info">
                  <div>
                    <div className="user-label">City</div>
                    <div className="label-value">
                      {manager ? manager.city : "NA"}
                    </div>
                  </div>
                  <div>
                    <div className="user-label">Office</div>
                    <div className="label-value">
                      {manager && manager.office_location
                        ? manager.office_location
                        : "NA"}
                    </div>
                  </div>
                </div>
                <hr />
                <div className="user-info">
                  <div>
                    <div className="user-label">Postal Code</div>
                    <div className="label-value">
                      {manager ? manager.postal_code : "NA"}
                    </div>
                  </div>
                </div>
              </div>
              {/* <div id="Performance" className="tab-pane fade">
                <div className="user-info">
                  <div>
                    <div className="user-label">Current Balance</div>
                    <div className="label-value">
                      {!isEmptyOrNull(balance)
                        ? "£" + addCommas(balance)
                        : "£0"}
                    </div>
                  </div>
                  <div>
                    <div className="user-label">Current Target</div>
                    <div className="label-value">
                      {!isEmptyOrNull(target) ? "£" + addCommas(target) : "£0"}
                    </div>
                  </div>
                </div>
                <hr />
                <div className="target-status-bar">
                  <div className="status">Target Status</div>
                  <div
                    className="progress"
                    style={{ backgroundColor: "#292932" }}
                  >
                    <div
                      className="progress-bar progress-bar-striped active"
                      role="progressbar"
                      aria-valuemin="0"
                      aria-valuemax="100"
                      style={{ width: `${progress}%` }}
                    >
                      {progress}% completed
                    </div>
                    <div
                      className="progress-bar progress-bar-striped active"
                      role="progressbar"
                      aria-valuemin="0"
                      aria-valuemax="100"
                      style={{
                        width: `${remaining}%`,
                        backgroundColor: "#292932",
                      }}
                    >
                      {remaining}% remaining
                    </div>
                  </div>
                </div>
              </div> */}
              <div id="Investors" className="tab-pane fade">
                <ManagersInvestors
                  investors={investorsData}
                  activePage={activePage}
                  perPage={perPage}
                  totalPages={investotsCount}
                  handlePageChange={(value) => handlePageChange(value)}
                />
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              style={{ backgroundColor: "#13131a", marginLeft: "5px" }}
              type="button"
              className="btn"
              data-dismiss="modal"
            >
              Close
            </button>
            <button
              type="button"
              className="btn btn-danger"
              data-dismiss="modal"
              onClick={() => onClickDelete(manager)}
            >
              Delete
            </button>
            <button
              type="button"
              className="btn btn-primary"
              data-dismiss="modal"
              data-toggle="modal"
              data-target="#addUserModal"
              onClick={() => onClickEdit(manager)}
            >
              Edit Details
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewManagerModal;
