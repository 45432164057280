import { managers } from "../../constants/actionTypes";
import swal from "sweetalert";
import { loaderState } from "../index";
import { USER_API } from "../../api";
import _ from "lodash";

export const getManagers = () => (dispatch) => {
  USER_API.get(`/managerDetails`)
    .then((res) => {
      dispatch(loaderState(false));
      dispatch({ type: managers.ALL_MANAGERS, payload: res.data });
    })
    .catch((err) => {
      swal("Oops!", err.message, "error");
      dispatch(loaderState(false));
    });
};

export const getTopManagers = () => (dispatch) => {
  let filter = {
    limit: 5,
    order: "date_time DESC",
  };
  USER_API.get(`/managerDetails?filter=${JSON.stringify(filter)}`)
    .then((res) => {
      dispatch(loaderState(false));
      dispatch({ type: managers.TOP_MANAGERS, payload: res.data });
    })
    .catch((err) => {
      dispatch(loaderState(false));
      swal("Oops!", err.message, "error");
    });
};

export const updateManagerStatus = (ids, status) => (dispatch, getState) => {
  const { all_managers } = getState().managers;
  let where = {
    id: { inq: { ...ids } },
  };

  USER_API.patch(`/managerDetails?where=${JSON.stringify(where)}`, { status })
    .then((res) => {
      _.forEach(all_managers, (manager) => {
        if (ids.includes(manager.id)) {
          manager.status = status;
        }
      });
      let action =
        status === "Closed"
          ? "deleted!"
          : status === "Active"
          ? "activated!"
          : "paused!";

      swal("Manager(s) " + action, {
        icon: "success",
      });
      dispatch(loaderState(false));
    })
    .catch((err) => {
      dispatch(loaderState(false));
      swal("Oops!", err.message, "error");
    });
};

export const selectedManager = (manager) => {
  return {
    type: managers.SELECTED_MANAGER,
    payload: manager,
  };
};

export const addManager = (data) => (dispatch) => {
  USER_API.post("/managerDetails", data)
    .then((res) => {
      dispatch(getManagers());
      swal("", "Investor added", "success");
    })
    .catch((err) => {
      dispatch(loaderState(false));
      swal("Oops!", err.message, "error");
    });
};

export const updateManager = (id, data) => (dispatch, getState) => {
  // const { all_managers } = getState().managers;
  USER_API.patch(`/managerDetails/${id}`, data)
    .then((res) => {
      dispatch(getManagers());
      // let storeData = [];
      // _.forEach(all_managers, (manager) => {
      //   if (manager.id === id) {
      //     storeData.push({ ...manager, ...data });
      //   } else {
      //     storeData.push(manager);
      //   }
      // });
      // dispatch({ type: managers.ALL_MANAGERS, payload: storeData });
      dispatch(loaderState(false));
    })
    .catch((err) => {
      dispatch(loaderState(false));
      swal("Oops!", err.message, "error");
    });
};
