import { rounds } from "../../constants/actionTypes";
import { USER_API } from "../../api";
import _, { add } from "lodash";
import { loaderState } from "../common";
import swal from "sweetalert";
import moment from "moment";
import $ from "jquery";

export const getRounds = () => (dispatch) => {
	let filter = {
		order: "start_date DESC",
	};
	USER_API.get(`/InvestmentRounds?filter=${JSON.stringify(filter)}`)
		.then((res) => {
			dispatch(loaderState(false));
			let newRounds = _.orderBy(res.data, ["start_date"], ["desc"]);
			dispatch({ type: rounds.ROUNDS, payload: newRounds });
		})
		.catch((err) => {
			dispatch(loaderState(false));
			swal("Oops!", err.message, "error");
		});
};

export const addRound = (data, status) => (dispatch, getState) => {
	const { all_rounds } = getState().rounds;
	const { login_user_info } = getState().auth;
	if (!status) {
		data.status = "Pending";
	}
	data.authorized_by = login_user_info.first_name + " " + login_user_info.last_name;

	USER_API.post(`/InvestmentRounds`, data)
		.then((res) => {
			dispatch(getRounds());
		})
		.catch((err) => {
			dispatch(loaderState(false));
			swal("Oops!", err.message, "error");
		});
};

export const updateInvestmentRound = (id, data) => (dispatch, getState) => {
	const { all_rounds } = getState().rounds;
	if (data.status === "Started") {
		data.start_date = moment(data.start_date).format();
	}
	if (data.status === "Completed") {
		data.complete_date = moment().format();
	}
	USER_API.patch(`/InvestmentRounds/${id}`, data)
		.then((res) => {
			let updatedData = [];
			_.forEach(all_rounds, (round) => {
				if (round.id === id) {
					updatedData.push({ ...round, ...data });
				} else {
					updatedData.push(round);
				}
			});
			dispatch({ type: rounds.ROUNDS, payload: updatedData });
			dispatch(loaderState(false));
		})
		.catch((err) => {
			dispatch(loaderState(false));
			swal("Oops!", err.message, "error");
		});
};

export const completedRound = (data) => (dispatch) => {
	USER_API.post(`/InvestmentRounds/individualInvestorRound`, data)
		.then((res) => {
			dispatch(getRounds());
			dispatch(loaderState(false));
			$(".close").click();
			swal({
				title: "Round Completed",
				text: "Do you want to start a new round for this investor!",
				icon: "success",
				buttons: {
					cancel: "No",
					catch: {
						text: "Yes",
						value: "Yes",
					},
				},
			}).then((value) => {
				switch (value) {
					case "Yes":
						let newData = {
							round_name: data.investor_account_id + "_" + moment().format("YY") + moment().format("MM") + moment().format("DD"),
							acount_number: data.investor_account_id,
							start_date: moment().format(),
						};
						dispatch(loaderState(true));
						dispatch(addRound(newData, "status"));
					default:
				}
			});
			// dispatch(getRounds());
		})
		.catch((err) => {
			dispatch(loaderState(false));
			swal("Oops!", err.message, "error");
		});
};

export const onSelectedRound = (round) => {
	return {
		type: rounds.SELECTED_ROUND,
		payload: round,
	};
};

export const addPastRound = (data) => (dispatch, getState) => {
	const { login_user_info } = getState().auth;
	let addData = {
		authorized_by: login_user_info.first_name + " " + login_user_info.last_name,
		round_name: data.round_name,
		start_date: data.start_date,
		acount_number: data.account_number,
		status: "Started",
	};

	USER_API.post(`/InvestmentRounds`, addData)
		.then((res) => {
			let completionData = {
				round_name: data.round_name,
				investor_account_id: data.account_number,
				complete_date: data.complete_date,
				status: data.status,
			};
			dispatch(completedRound(completionData));
		})
		.catch((err) => {
			dispatch(loaderState(false));
			swal("Oops!", err.message, "error");
		});
};

export const deteleRound = (id) => (dispatch, getState) => {
	//console.log(id.id);
	let all_rounds = getState().rounds.all_rounds;

	USER_API.delete(`/InvestmentRounds/${id.id}`)
		.then((res) => {
			dispatch(loaderState(true));

			let updatedRounds = [];
			_.forEach(all_rounds, (round) => {
				if (round.id !== id) {
					updatedRounds.push(round);
				}
			});
			dispatch({
				type: rounds.ROUNDS,
				payload: updatedRounds,
			});
			dispatch(getRounds());

			dispatch(loaderState(false));
		})
		.catch((err) => {
			dispatch(loaderState(false));
			swal("Oops!", err.message, "error");
		});
};
