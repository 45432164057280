import React from "react";
import PropTypes from "prop-types";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { tableStyles } from "../Common/StyleComponents";
import moment from "moment";
import startIcon from "../../assets/icons/start2.png";
import completeIcon from "../../assets/icons/complete1.png";
import notesIcon from "../../assets/icons/notes1.png";
import timesIcon from "../../assets/icons/times.png";
import { getProgress } from "../../helpers";

const headCells = [
  // {
  //   id: "roundName",
  //   numeric: false,
  //   disablePadding: true,
  //   label: "ROUND NAME",
  // },
  {
    id: "account_number",
    numeric: false,
    disablePadding: true,
    label: "INVESTOR",
  },
  {
    id: "startDate",
    numeric: false,
    disablePadding: false,
    label: "START DATE",
  },
  {
    id: "completeDate",
    numeric: false,
    disablePadding: false,
    label: "COMPLETE DATE",
  },
  { id: "progress", numeric: false, disablePadding: false, label: "PROGRESS" },
  { id: "status", numeric: false, disablePadding: false, label: "STATUS" },
  {
    id: "authorizedBy",
    numeric: false,
    disablePadding: false,
    label: "AUTHORIZED BY",
  },
  { id: "actions", numeric: false, disablePadding: false, label: "ACTIONS" },
  // {
  //   id: "statement",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "STATEMENT",
  // },
];

function EnhancedUserTableHead(props) {
  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox"></TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedUserTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function EnhancedUserTable({
  rows,
  onChangeStatus,
  // isStartedRound,
  onFilterTextChange,
  selectedRound,
  onSelectRound,
  onClickStart,
  onClickDelete,
}) {
  const classes = tableStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Paper className={classes.paper}>
      <div className="row" style={{ padding: "15px 15px 0 15px" }}>
        <div className="col-sm-12">
          <div className="form-group">
            <input
              style={{ backgroundColor: "#1c1c24" }}
              type="text"
              placeholder="Search Investment Rounds"
              className="form-control"
              id="search-investments"
              onChange={(e) => onFilterTextChange(e)}
            />
            {/* <div className="icon-addon addon-md">
              <input
                style={{ backgroundColor: "#1c1c24" }}
                type="text"
                placeholder="Search Investment"
                className="form-control"
                id="search-investments"
                onChange={(e) => onFilterTextChange(e)}
              />
              <label
                htmlFor="search-investments"
                className="glyphicon glyphicon-search"
                rel="Search Investment"
                title="Search Investment"
              ></label>
            </div> */}
          </div>
        </div>
      </div>
      <TableContainer>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          aria-label="enhanced table"
        >
          <EnhancedUserTableHead
            classes={classes}
            rowCount={rows ? rows.length : 0}
          />
          <TableBody>
            {rows
              ? rows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const labelId = `enhanced-table-checkbox-${index}`;

                    let statusStartedOrComp =
                      row.status === "Started" || row.status === "Completed"
                        ? true
                        : false;

                    let statusPendingOrComp =
                      row.status === "Pending" || row.status === "Completed"
                        ? true
                        : false;

                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.id}
                        onMouseEnter={() => selectedRound(row)}
                      >
                        <TableCell
                          style={{ width: "1%" }}
                          padding="checkbox"
                        ></TableCell>
                        {/* <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                          style={{ color: "gray", width: "10%" }}
                        >
                          {row.round_name}
                        </TableCell> */}
                        <TableCell
                          component="th"
                          scope="row"
                          padding="none"
                          style={{ color: "gray" }}
                        >
                          {row.investor_name} ({row.account_number})
                        </TableCell>
                        <TableCell align="left" style={{ color: "gray" }}>
                          {row.start_date
                            ? moment(row.start_date).format("DD/MM/YYYY")
                            : "-"}
                        </TableCell>
                        <TableCell align="left" style={{ color: "gray" }}>
                          {row.complete_date
                            ? moment(row.complete_date).format("DD/MM/YYYY")
                            : ""}
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{ color: "gray", width: "20%" }}
                        >
                          <div
                            className="progress"
                            style={{ backgroundColor: "#292932" }}
                          >
                            <div
                              title={`${
                                row.remainingDays === 32
                                  ? 30 + "days Completed"
                                  : 30 - row.remainingDays + " days Completed"
                              }`}
                              className={`progress-bar progress-bar-striped active ${
                                row.status === "Completed"
                                  ? "progress-bar-success"
                                  : ""
                              }`}
                              role="progressbar"
                              aria-valuemin="0"
                              aria-valuemax="100"
                              style={{
                                width: `${row.progress}%`,
                              }}
                            >
                              {row.status === "Completed"
                                ? "Completed"
                                : row.remainingDays === 32
                                ? 30 + " days Completed"
                                : 30 - row.remainingDays + " days Completed"}
                            </div>
                            <div
                              title={`${
                                !row.start_date
                                  ? "Not Started"
                                  : row.remainingDays + " days remaining"
                              } `}
                              className="progress-bar progress-bar-striped active"
                              role="progressbar"
                              aria-valuemin="0"
                              aria-valuemax="100"
                              style={{
                                width: `${
                                  row.progress === 100
                                    ? "0%"
                                    : 100 - row.progress
                                }%`,
                                backgroundColor: "#292932",
                              }}
                            >
                              {`${
                                !row.start_date
                                  ? "Not Started"
                                  : row.remainingDays + " days remaining"
                              } `}
                            </div>
                          </div>
                        </TableCell>
                        <TableCell align="left">
                          {row.status ? (
                            <span
                              className="status"
                              style={{
                                color:
                                  row.status === "Completed"
                                    ? "#27AE60"
                                    : row.status === "Started"
                                    ? "#1F618D"
                                    : "#FFFF00",
                                backgroundColor:
                                  row.status === "Completed"
                                    ? "rgb(34, 153, 84, 0.2)"
                                    : row.status === "Started"
                                    ? "rgb(31, 97, 141, 0.2)"
                                    : "rgb(255,255,0, 0.2)",
                              }}
                            >
                              {row.status}
                            </span>
                          ) : null}
                        </TableCell>
                        <TableCell align="left" style={{ color: "gray" }}>
                          {row.authorized_by}
                        </TableCell>
                        <TableCell style={{ width: "13%" }}>
                          <ul className="action-btns">
                            <li
                              data-toggle="modal"
                              data-target="#start-round-modal"
                              disabled={statusStartedOrComp ? "disabled" : ""}
                              title={
                                statusStartedOrComp
                                  ? "Round is either completed or not started"
                                  : ""
                              }
                              style={{
                                pointerEvents: statusStartedOrComp
                                  ? "none"
                                  : "",
                              }}
                              onClick={() => onClickStart(row.id)}
                            >
                              <img
                                alt="start icon"
                                src={startIcon}
                                width="15px"
                                height="15px"
                              />
                            </li>
                            <li
                              disabled={statusPendingOrComp ? "disabled" : ""}
                              title={
                                statusPendingOrComp
                                  ? "Round is either completed or not started"
                                  : ""
                              }
                              style={{
                                pointerEvents: statusPendingOrComp
                                  ? "none"
                                  : "",
                              }}
                              data-toggle="modal"
                              data-target="#complete-round-modal"
                              onClick={() => onSelectRound(row)}
                            >
                              <img
                                alt="complete icon"
                                src={completeIcon}
                                width="15px"
                                height="15px"
                              />
                            </li>
                            <li
                              title="write notes"
                              data-toggle="modal"
                              data-target="#add-notes-modal"
                            >
                              <img
                                alt="notes icon"
                                src={notesIcon}
                                width="15px"
                                height="15px"
                              />
                            </li>
                            <li
                              disabled={
                                row.status === "Completed" ? "disabled" : ""
                              }
                              pointerEvents={
                                row.status === "Completed" ? "none" : ""
                              }
                              title="delete investment round"
                              onClick={() => onClickDelete(row)}
                            >
                              <img
                                src={timesIcon}
                                alt="delete icon"
                                width="18px"
                                height="18px"
                              />
                            </li>
                          </ul>
                          {/* <div className="btn-group" id="investment-group-btns">
                            <button
                              type="button"
                              className="btn btn-default btn-sm"
                              style={{ color: "gray" }}
                              disabled={
                                statusStartedOrComp
                                  ? "disabled"
                                  : ""
                              }
                              title={
                                row.status === "Started" ||
                                row.status === "Completed"
                                  ? "Round is either completed or not started"
                                  : ""
                              }
                              onClick={() => onChangeStatus(row.id, "Started")}
                            >
                              Start
                            </button>
                            <button
                              type="button"
                              className="btn btn-default btn-sm"
                              style={{ color: "gray" }}
                              disabled={
                                row.status === "Pending" ||
                                row.status === "Completed"
                                  ? "disabled"
                                  : ""
                              }
                              title={
                                row.status === "Pending" ||
                                row.status === "Completed"
                                  ? "Round is either completed or not started"
                                  : ""
                              }
                              onClick={() => onSelectRound(row)}
                            >
                              Complete
                            </button>
                            <button
                              type="button"
                              className="btn btn-default btn-sm"
                              style={{ color: "gray" }}
                              data-toggle="modal"
                              data-target="#add-notes-modal"
                            >
                              Notes
                            </button>
                          </div> */}
                        </TableCell>
                        {/* <TableCell align="left" style={{ color: "gray" }}>
                          {row.status === "Completed" ? (
                            <>
                              <span className="rounds-csv">CSV</span>
                              <span className="rounds-pdf">PDF</span>
                            </>
                          ) : null}
                        </TableCell> */}
                      </TableRow>
                    );
                  })
              : null}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 15]}
        component="div"
        count={rows ? rows.length : 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
