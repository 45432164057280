import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getDocuments,
  loaderState,
  rejectDocument,
  verifyDocument,
} from "../../actions";
import DocumentsComp from "../../components/documents";
import swal from "sweetalert";

class Documents extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: [],
    };
  }

  componentDidMount() {
    this.props.loaderState(true);
    this.props.getDocuments();
  }

  setSelected = (newSelected) => {
    this.setState({ selected: newSelected });
  };

  onClickReject(id) {
    swal({
      title: "Are you sure?",
      text: "You want to reject this document!",
      icon: "warning",
      buttons: ["Cancel", "Update"],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        this.props.loaderState(true);
        this.props.rejectDocument(id);
      }
    });
  }

  onClickVerify(id) {
    swal({
      title: "Are you sure?",
      text: "You want to verfiy this document!",
      icon: "warning",
      buttons: ["Cancel", "Update"],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        this.props.loaderState(true);
        this.props.verifyDocument(id);
      }
    });
  }

  render() {
    let stateData = { ...this.state };
    const { documents } = this.props;

    return (
      <DocumentsComp
        documents={documents}
        setSelected={(newSelected) => this.setSelected(newSelected)}
        stateData={stateData}
        onClickReject={(id) => this.onClickReject(id)}
        onClickVerify={(id) => this.onClickVerify(id)}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    documents: state.documents.all_documents,
  };
};

export default connect(mapStateToProps, {
  getDocuments,
  loaderState,
  rejectDocument,
  verifyDocument,
})(Documents);
