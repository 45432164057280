import React from "react";
import { addCommas, userTier, getCurrencySymbol } from "../../helpers";

const TopInvestors = ({ topInvestors, goto }) => {
  return (
    <>
      <div className="top-investors">
        {topInvestors ? (
          topInvestors.map((investor) => (
            <div className="investor" key={investor.id}>
              <div className="left-content">
                <div className="media">
                  <div className="media-body">
                    <div className="media-heading">
                      <span className="name">
                        {investor.first_name} {investor.last_name}
                      </span>
                      <br />
                      <span className="category">
                        {investor.balance && userTier(investor.balance)
                          ? userTier(investor.balance)
                          : "No Tier"}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div></div>
              <div className="right-content">
                {investor.balance
                  ? getCurrencySymbol(investor.currency) +
                    addCommas(investor.balance)
                  : getCurrencySymbol(investor.currency) + 0}
              </div>
            </div>
          ))
        ) : (
          <div className="no-data">
            <i>No data</i>
          </div>
        )}
        {topInvestors ? (
          <>
            <hr />
            <div className="view-investors" onClick={() => goto("/investors")}>
              VIEW ALL INVESTORS
            </div>
          </>
        ) : null}
      </div>
    </>
  );
};

export default TopInvestors;
