import React from "react";
import { Link } from "react-router-dom";

//icons
import logo from "../../assets/icons/DeepReef-Logo.png";
import dashboard from "../../assets/icons/dashboard_icon.png";
import transactions from "../../assets/icons/transactions_icon.png";
import withDrawal from "../../assets/icons/withdrawal_icon.png";
import user from "../../assets/icons/user_profile_icon.png";
import logout from "../../assets/icons/logout_icon.png";
import document from "../../assets/icons/notes.png";

export default function Sidebar({ nav, onChangeNav }) {
  return (
    <>
      <a id="show-sidebar" className="btn btn-lg" href="#">
        <i className="fas fa-bars"></i>
      </a>
      <nav id="sidebar" className="sidebar-wrapper">
        <div className="sidebar-content">
          <div className="sidebar-brand">
            <a href="/dashboard">
              <img src={logo} height="30" width="80" />
            </a>
            <div id="close-sidebar">
              <i className="fas fa-times"></i>
            </div>
          </div>
          <div className="sidebar-menu">
            <ul>
              <li className="header-menu" style={{ height: "30px" }}></li>
              <li className={nav == "/dashboard" ? "sidenav-active" : ""}>
                <Link
                  to="/dashboard"
                  onClick={() => onChangeNav("/dashboard")}
                  href="#"
                >
                  <img src={dashboard} />
                  <span>Dashboard</span>
                </Link>
              </li>
              <li className={nav == "/transactions" ? "sidenav-active" : ""}>
                <Link
                  to="/transactions"
                  onClick={() => onChangeNav("/transactions")}
                  href="#"
                >
                  <img src={transactions} />
                  <span>Transactions</span>
                </Link>
              </li>
              <li className={nav == "/withdrawal" ? "sidenav-active" : ""}>
                <Link
                  to="/withdrawal"
                  onClick={() => onChangeNav("/withdrawal")}
                  href="#"
                >
                  <img src={withDrawal} />
                  <span>WithDrawal</span>
                </Link>
              </li>
              <li className={nav == "/investors" ? "sidenav-active" : ""}>
                <Link
                  to="/investors"
                  onClick={() => onChangeNav("/investors")}
                  href="#"
                >
                  <img src={user} />
                  <span>Investors</span>
                </Link>
              </li>
              <li className={nav == "/managers" ? "sidenav-active" : ""}>
                <Link
                  to="/managers"
                  onClick={() => onChangeNav("/managers")}
                  href="#"
                >
                  <img src={user} />
                  <span>Managers</span>
                </Link>
              </li>
              <li
                className={nav == "/investmentrounds" ? "sidenav-active" : ""}
              >
                <Link
                  to="/investmentrounds"
                  onClick={() => onChangeNav("/investmentrounds")}
                  href="#"
                >
                  <img src={withDrawal} />
                  <span>Investment Rounds</span>
                </Link>
              </li>
              <li className={nav == "/documents" ? "sidenav-active" : ""}>
                <Link
                  to="/documents"
                  onClick={() => onChangeNav("/documents")}
                  href="#"
                >
                  {/* <img src={document} /> */}
                  <i className="fa fa-file" style={{ fontSize: "1.2em" }} />
                  <span>Documents</span>
                </Link>
              </li>
              <li className={nav == "/" ? "sidenav-active" : ""}>
                <Link to="/" onClick={() => onChangeNav("/")} href="#">
                  <img src={logout} />
                  <span>Logout</span>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}
