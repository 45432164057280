import React from "react";
import { connect } from "react-redux";
import { selectedNav, loaderState, signIn } from "../../actions";
import { isEmptyOrNull } from "../../helpers";

import LoginComp from "../../components/Login";
import swal from "sweetalert";

class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  onChange = ({ target }) => {
    const { id, value } = target;
    this.setState({ [id]: value });
  };

  onClickLogin = () => {
    const { email, password } = this.state;
    if (isEmptyOrNull(email) && isEmptyOrNull(password)) {
      swal("", "Please enter your email address and password", "info");
    } else if (isEmptyOrNull(email)) {
      swal("", "Please enter your email address", "info");
    } else if (isEmptyOrNull(password)) {
      swal("", "Please enter your password", "info");
    } else {
      this.props.loaderState(true);
      this.props.signIn(email, password);
    }
    // this.props.selectedNav("/dashboard");
    // goto("/dashboard");
  };

  render() {
    return (
      <LoginComp
        onChange={(e) => this.onChange(e)}
        onClickLogin={() => this.onClickLogin()}
      />
    );
  }
}

export default connect(null, { selectedNav, signIn, loaderState })(Login);
